<template>
  <BOverlay
    :show="pending"
    opacity="0.5"
    spinner-variant="primary"
  >
    <div class="reconciliation-form">
      <BTabs v-model="activeTab">
        <BTab
          :title="$t('activities.resources.production')"
        >
          <div
            v-if="!readOnly"
            class="d-flex align-items-center my-4"
          >
            <ProductionReconciliationFormInputFieldsInfo />

            <div class="ml-5">
              <BButton
                size="sm"
                variant="outline-secondary"
                class="text-nowrap"
                @click="requestEntries"
              >
                <i class="fas fa-refresh mr-1" />
                {{ $t('activities.productionReconciliation.lastRefresh') }}:
                {{ lastRefreshDateText }}
              </BButton>
            </div>
          </div>

          <ProductionReconciliationTable
            v-show="activeTab === 0"
            :key="lastRefreshDate"
            :value-column-label="$t('activities.resources.production')"
            :entries="productionEntries"
            :unit="orderUnit"
            :counter-value="Number(orderDone) || 0"
            :new-entry-object.sync="newEntryObject"
            :order-start-date="orderBeginDate"
            :order-end-date="orderEndDate"
            :read-only="readOnly"
            @enter-pressed="addEntry()"
            @delete-entry="deleteEntry($event)"
          />

          <div
            v-if="!readOnly"
            class="d-flex justify-content-center mt-4"
          >
            <BButton
              size="sm"
              class="mr-5 w-25"
              @click="hideModal"
            >
              {{ $t('general.cancel') }}
            </BButton>
            <BButton
              size="sm"
              class="w-25"
              variant="primary"
              :disabled="!isSaveEnabled"
              @click="addEntry()"
            >
              <i class="fas fa-check mr-1" />
              <span v-if="orderFinish">
                {{ $t('activities.productionReconciliation.saveAndFinishOrder') }}
              </span>
              <span v-else>
                {{ $t('activities.productionReconciliation.saveEntry') }}
              </span>
            </BButton>
          </div>
        </BTab>
        <BTab
          :title="$t('activities.resources.waste')"
        >
          <div
            v-if="!readOnly"
            class="d-flex align-items-center my-4"
          >
            <ProductionReconciliationFormInputFieldsInfo />

            <div class="ml-5">
              <BButton
                size="sm"
                variant="outline-secondary"
                class="text-nowrap"
              >
                <i class="fas fa-refresh mr-1" />
                {{ $t('activities.productionReconciliation.lastRefresh') }}:
                {{ lastRefreshDateText }}
              </BButton>
            </div>
          </div>

          <ProductionReconciliationTable
            v-show="activeTab === 1"
            :key="lastRefreshDate"
            :value-column-label="$t('activities.resources.waste')"
            :entries="wasteEntries"
            display-as-waste
            :unit="orderUnit"
            :counter-value="Number(orderWaste) || 0"
            :new-entry-object.sync="newEntryObject"
            :read-only="readOnly"
            :order-start-date="orderBeginDate"
            :order-end-date="orderEndDate"
            @delete-entry="deleteEntry($event)"
            @enter-pressed="addEntry()"
          />

          <div
            v-if="!readOnly"
            class="d-flex justify-content-center mt-4"
          >
            <BButton
              size="sm"
              class="mr-5 w-25"
              @click="hideModal"
            >
              {{ $t('general.cancel') }}
            </BButton>
            <BButton
              size="sm"
              class="w-25"
              variant="primary"
              :disabled="!isSaveEnabled"
              @click="addEntry()"
            >
              <i class="fas fa-check mr-1" />

              <span v-if="orderFinish">
                {{ $t('activities.productionReconciliation.saveAndFinishOrder') }}
              </span>
              <span v-else>
                {{ $t('activities.productionReconciliation.saveEntry') }}
              </span>
            </BButton>
          </div>
        </BTab>
      </BTabs>
    </div>
  </BOverlay>
</template>

<script>
/* eslint-disable max-len */
import ProductionReconciliationFormInputFieldsInfo from '@/components/activity/productionReconciliation/ProductionReconciliationFormInputFieldsInfo';
import ProductionReconciliationTable from '@/components/activity/productionReconciliation/ProductionReconciliationTable';
/* eslint-enable max-len */
import loopRequest from '@/mixins/loopRequest';
import { activityResourceType } from '@/store/modules/activities';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProductionReconciliationForm',
  props: {
    activityId: String,
    orderBeginDate: String,
    orderEndDate: String,
    orderLineId: String,
    orderUnit: String,
    orderDone: Number,
    orderWaste: Number,
    readOnly: Boolean,
    orderFinish: Boolean,
  },
  data: () => ({
    activeTab: 0,

    pending: false,
    entries: [],
    lastRefreshDate: null,
    lastRefreshDateText: '',

    newEntryObject: {
      to: '',
      value: 0,
      resourceType: '',
    },
  }),
  components: {
    ProductionReconciliationTable,
    ProductionReconciliationFormInputFieldsInfo,
  },
  mixins: [
    loopRequest('updateLastRefreshText', 1000),
  ],
  computed: {
    ...mapGetters([
      'plantId',
    ]),
    showEntryValueError() {
      return this.newEntryObject.value
        && (this.newEntryObject.value < 0 || this.newEntryObject.value > 1000000000);
    },
    productionEntries() {
      return this.entries.filter(e => e.counterType === this.capitalize(activityResourceType.production));
    },
    lastProductionEntryValue() {
      return this.productionEntries.length > 0
        ? this.productionEntries[this.productionEntries.length - 1].total
        : 0;
    },
    wasteEntries() {
      return this.entries.filter(e => e.counterType === this.capitalize(activityResourceType.waste));
    },
    lastWasteEntryValue() {
      return this.wasteEntries.length > 0
        ? this.wasteEntries[this.wasteEntries.length - 1].total
        : 0;
    },
    isSaveEnabled() {
      return this.newEntryObject.value
        && this.newEntryObject.to
        && moment(this.newEntryObject.to).unix() > moment(this.orderBeginDate).unix()
        && this.newEntryObject.resourceType
        && !this.showEntryValueError;
    },
  },
  methods: {
    ...mapActions([
      'completeOrder',
      'getActivityManualProduction',
      'addOrderManualProduction',
      'removeOrderManualProduction',
    ]),
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    updateLastRefreshText() {
      if (!this.lastRefreshDate) return;
      this.lastRefreshDateText = moment.unix(this.lastRefreshDate).fromNow();
    },
    async requestEntries() {
      try {
        this.pending = true;
        const { data } = await this.getActivityManualProduction({
          params: {
            plantId: this.plantId,
            activityId: this.activityId,
          },
        });
        this.entries = data;
        this.lastRefreshDate = moment().unix();
        this.updateLastRefreshText();
      } catch ({ response }) {
        this.$root.$bvToast
          .toast(response.data?.detail || JSON.stringify(response.data), {
            title: this.$t('error.error'),
            variant: 'danger',
            appendToast: true,
            toaster: 'b-toaster-top-right',
          });
      } finally {
        this.pending = false;
      }
    },
    async addEntry() {
      if (!this.isSaveEnabled) return;

      try {
        this.pending = true;

        if (this.orderFinish) {
          let done = this.lastProductionEntryValue;
          let wasted = this.lastWasteEntryValue;
          if (this.newEntryObject.resourceType === activityResourceType.production) {
            done = this.newEntryObject.value;
          } else {
            wasted = this.newEntryObject.value;
          }

          await this.completeOrder({
            params: {
              orderId: this.activityId,
              query: {
                plantId: this.plantId,
              },
            },
            data: {
              manualOverride: true,
              done,
              wasted,
            },
          });

          this.$nextTick(() => {
            this.hideModal();
          });
          return;
        }

        const { data } = await this.addOrderManualProduction({
          params: {
            plantId: this.plantId,
            orderId: this.activityId,
          },
          data: {
            value: this.newEntryObject.value,
            resourceType: this.newEntryObject.resourceType,
            to: this.newEntryObject.to,
            unit: this.orderUnit,
          },
        });
        data.operationIds.forEach(id => {
          this.$root.$emit('user-operation-requested', { id, type: 'ManualProductionMessage' });
        });
      } catch ({ response }) {
        this.$root.$bvToast
          .toast(response.data?.detail || JSON.stringify(response.data), {
            title: this.$t('error.error'),
            variant: 'danger',
            appendToast: true,
            toaster: 'b-toaster-top-right',
          });
      } finally {
        this.pending = false;
      }
    },
    async deleteEntry(entry) {
      try {
        this.pending = true;
        const { data } = await this.removeOrderManualProduction({
          params: {
            plantId: this.plantId,
            orderId: this.activityId,
            query: {
              to: entry.timestamp,
              resourceType: this.newEntryObject.resourceType,
            },
          },
        });
        data.operationIds.forEach(id => {
          this.$root.$emit('user-operation-requested', { id, type: 'ManualProductionMessage' });
        });
      } catch ({ response }) {
        this.$root.$bvToast
          .toast(response.data?.detail || JSON.stringify(response.data), {
            title: this.$t('error.error'),
            variant: 'danger',
            appendToast: true,
            toaster: 'b-toaster-top-right',
          });
      } finally {
        this.pending = false;
      }
    },
    hideModal() {
      this.$emit('hide');

      if (this.orderFinish) return;
      this.$router.replace({
        query: {
          ...this.$route.query,
          productionReconciliation: false,
        },
      });
    },
    async operationCompleted() {
      await this.requestEntries();
      if (this.orderFinish) {
        this.$nextTick(() => {
          this.hideModal();
        });
      }
    },
  },
  created() {
    this.requestEntries();
  },
  mounted() {
    this.$root.$on('user-operation-completed', this.operationCompleted);
  },
  destroyed() {
    this.$root.$off('user-operation-completed', this.operationCompleted);
  },

};
</script>

<style scoped lang="scss">
.reconciliation-form {
  &:deep() {
    .nav-tabs {
      .nav-item {
        width: 25%;
        font-size: 14px;
        text-align: center;

        .nav-link {
          color: gray;
          margin-bottom: -3px;
          transition: 0.2s all;
          border-top: unset;
          border-left: unset;
          border-right: unset;

          &.active, &:hover {
            color: black;
            border-bottom: 5px solid var(--blue);
            border-radius: 0;
            border-top: unset;
            border-left: unset;
            border-right: unset;
            font-weight: bold;
          }

        }
      }
    }
  }
}
</style>
