<template>
  <Apexcharts
    ref="chart"
    :key="key"
    :type="type === 'bar' ? 'bar' : 'area'"
    :options="compact ? compactOptions : options"
    :series="series"
    :height="height"
    @click="handleClick($event)"
  />
</template>

<script>
import Apexcharts from 'vue-apexcharts';

export default {
  props: {
    height: {
      type: [Number, String],
      default: 500,
    },
    series: Array,
    labelsColors: Array,
    labels: Array,
    annotations: Array,
    max: Number,
    type: String,
    compact: Boolean,
  },
  data() {
    return {
      key: 1,
      fullView: true,
    };
  },
  components: {
    Apexcharts,
  },
  computed: {
    compactOptions() {
      return {
        ...this.options,
        xaxis: {
          ...this.options.xaxis,
          labels: {
            maxHeight: 24,
            rotate: 0,
            hideOverlappingLabels: true,
          },
        },
        yaxis: {
          ...this.options.yaxis,
          hideOverlappingLabels: true,
          forceNiceScale: true,
          labels: {
            minWidth: 10,
            maxHeight: 10,
            offsetX: -10,
          },
        },
      };
    },
    options() {
      return {
        labels: this.labels,
        legend: {
          markers: {
            fillColors: this.labelsColors,
          },
        },
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
            tools: {
              download: false,
              zoom: !this.fullView,
              zoomin: !this.fullView,
              zoomout: !this.fullView,
              pan: !this.fullView,
              reset: !this.fullView,
              customIcons: [{
                icon: '<i class="ion ion-md-expand" />',
                title: this.$t('reports.chart.toggleFullView'),
                class: 'custom-icon ml-1 mt-1',
                // Undefined click causes error
                click: () => {
                },

              }],

            },
            autoSelected: this.fullView ? undefined : 'pan',
          },
          zoom: this.fullView ? {} : {
            enabled: true,
            autoScaleYaxis: true,
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
            columnWidth: '90%',
            colors: {
              backgroundBarColors: ['rgba(180, 180, 180, 0.1)'],
            },
          },
        },
        dataLabels: {
          enabled: this.labels.length < 50,
          formatter: v => `${v}%`,
          offsetY: this.type === 'bar' ? -15 : 0,
          style: {
            fontSize: '11px',
            colors: this.series.map(x => x.labelColor),
          },
        },
        tooltip: {
          y: {
            formatter: v => `${v}%`,
          },
        },
        annotations: {
          yaxis: this.annotations.map(a => ({
            y: a.value,
            borderColor: a.color,
            strokeDashArray: 3,
            borderWidth: 2,
            opacity: 0.8,
            label: {
              text: `${a.labelPrefix}: ${a.value}%`,
              borderColor: a.color,
              offsetX: this.fullView ? 0 : -70,
              style: {
                color: '#fff',
                background: a.color,
                fontSize: 14,
              },
            },
          })),
        },
        yaxis: {
          min: 0,
          max: this.max,
          labels: {
            offsetY: this.fullView ? 0 : -5,
          },
        },
        xaxis: this.fullView ? {
          labels: {
            trim: true,
            maxHeight: 230,
            rotate: -75,
            hideOverlappingLabels: true,
          },
        } : {
          labels: {
            hideOverlappingLabels: true,
          },
          categories: this.labels,
          tickPlacement: 'on',
        },
        grid: this.fullView ? {} : {
          padding: {
            left: -30 * this.labels.length,
            right: -30 * this.labels.length,
          },
        },
      };
    },
  },
  watch: {
    series() {
      this.key += 1;
    },
    options() {
      this.key += 1;
    },
  },
  methods: {
    handleClick(e) {
      if (e?.srcElement?.className === 'ion ion-md-expand') {
        this.fullView = !this.fullView;
      }
    },
  },
};
</script>
