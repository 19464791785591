<template>
  <OEEHistoryChart
    :type="type"
    :annotations="annotations"
    :series="series"
    :labels="areNewLabelsOk ? labels : oldLabels"
    :labels-colors="labelColors"
    :height="height"
    :max="max"
    :compact="compact"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { createLabels } from '@/components/charts/labels';
import moment from 'moment';
import OEEHistoryChart from './OEEHistoryChart';

export default {
  props: {
    oees: {
      type: Array,
      required: true,
    },
    type: String,
    check: Object,
    showColors: Boolean,
    height: Number,
    showTargets: {
      type: Boolean,
      default: true,
    },
    andon: Boolean,
    forceMax: Number,
    target: Object,
    resolution: Object,
    compact: Boolean,
  },
  data: () => ({
    plantTime: null,
  }),
  components: {
    OEEHistoryChart,
  },
  computed: {
    ...mapGetters(['plantNow', 'isMobile']),
    ...mapGetters('plant', ['shifts']),
    annotations() {
      if (!this.showTargets || !this.target) return [];
      const targets = Object.keys(this.target);

      const t = targets
        .filter(k => !this.check || this.check[k])
        .filter(k => this.pallete[k])
        .map(k => ({
          labelPrefix: this.targetNames[k],
          color: this.pallete[k],
          value: this.target[k],
        }))
        .filter(x => x.value > 0);
      if (t.length > 0) {
        return t;
      }
      return [{
        labelPrefix: this.$t('oee.target'),
        color: '#4A9FFF',
        value: 80,
      }];
    },
    max() {
      const seriesMax = this.series
        .flatMap(x => x.data)
        .reduce((acc, curr) => Math.max(acc, curr), 100);

      return Math.min(seriesMax, 200);
    },
    singleSeries() {
      return Object.values(this.check).filter(x => x).length === 1;
    },
    labels() {
      const newAggregate = this.resolution.aggregate.length
        ? this.resolution.aggregate
        : ['day'];
      const aggregate = (typeof this.resolution.aggregate === 'string')
        ? this.parseAggregate(this.resolution.aggregate)
        : newAggregate;

      return createLabels(this.oees || [], aggregate);
    },
    areNewLabelsOk() {
      return this.labels.every(l => l !== '');
    },
    oldLabels() {
      if (this.resolution.orders) {
        return this.oees.map(x => {
          const startTime = moment(x.from).format('HH:mm DD/MM');
          const endTime = moment(x.from).format('HH:mm DD/MM');
          const time = `${startTime} - ${endTime}`;
          const oname = (x.order ? x.order.orderNumber : '?');
          if (!x.shiftId) return `${time} - ${oname}`;
          const s = this.shifts.find(shift => shift.id === x.shiftId);
          return `${time} | ${oname} | ${s ? s.description : '?'}`;
        });
      }

      if (this.resolution.shifts) {
        return this.oees
          .map(t => {
            const shift = this.shifts.find(({ id }) => t.shiftId === id);
            const time = moment(t.from).format(this.resolution.format);
            return shift ? `${time} / ${shift.name}` : time;
          });
      }

      return this.oees.map(el => moment(el.from).format(this.resolution.format));
    },
    pallete() {
      return {
        oee: '#4A9FFF',
        oeeA: '#FFC1AE',
        oeeP: this.$color('teal'),
        oeeQ: this.$color('yellow'),
        teep: '#61C9A8',
      };
    },
    labelColors() {
      return this.series.map(x => x.color({ value: 100 }));
    },
    targetNames() {
      return {
        oee: `${this.$t('oee.target')} ${this.$t('oee.oee')}`,
        oeeA: `${this.$t('oee.target')} ${this.$t('oee.availability')}`,
        oeeP: `${this.$t('oee.target')} ${this.$t('oee.performance')}`,
        oeeQ: `${this.$t('oee.target')} ${this.$t('oee.quality')}`,
        teep: `${this.$t('oee.target')} ${this.$t('oee.teep')}`,
      };
    },
    series() {
      const { round, pallete, check } = this;
      const singleSeriesLabels = '#666';

      const series = [];
      if ((!check || check.teep) && !this.andon) {
        const data = this.oees.map(curr => round(curr.oee.teep));

        series.push({
          name: this.$t('oee.teep'),
          color: ({ value }) => this.getColor(value, pallete.teep, this.target.teep),
          labelColor: this.singleSeries ? singleSeriesLabels : pallete.teep,
          data,
        });
      }

      if (!check || check.oee) {
        const data = this.oees.map(curr => round(curr.oee.oee));

        series.push({
          name: this.$t('oee.oee'),
          color: ({ value }) => this.getColor(value, pallete.oee, this.target.oee),
          labelColor: this.singleSeries ? singleSeriesLabels : pallete.oee,
          data,
        });
      }
      if (!check || check.oeeA) {
        const data = this.oees.map(curr => round(curr.oee.oeeA));

        series.push({
          name: this.$t('oee.availability'),
          color: ({ value }) => this.getColor(value, pallete.oeeA, this.target.oeeA),
          labelColor: this.singleSeries ? singleSeriesLabels : pallete.oeeA,
          data,
        });
      }
      if (!check || check.oeeP) {
        const data = this.oees.map(curr => round(curr.oee.oeeP));

        series.push({
          name: this.$t('oee.performance'),
          color: ({ value }) => this.getColor(value, pallete.oeeP, this.target.oeeP),
          labelColor: this.singleSeries ? singleSeriesLabels : pallete.oeeP,
          data,
        });
      }
      if (!check || check.oeeQ) {
        const data = this.oees.map(curr => round(curr.oee.oeeQ));

        series.push({
          name: this.$t('oee.quality'),
          color: ({ value }) => this.getColor(value, pallete.oeeQ, this.target.oeeQ),
          labelColor: this.singleSeries ? singleSeriesLabels : pallete.oeeQ,
          data,
        });
      }
      return series;
    },
  },
  methods: {
    round(v) {
      return Math.round(v);
    },
    getColor(v, seriesColor, target) {
      const val = this.round(v);
      if (this.singleSeries) {
        const low = 0.3 * (target || 80);
        const high = 1 * (target || 80);

        if (val >= high) {
          return this.$color('work');
        }
        if (val < high && val >= low) {
          return this.$color('orange');
        }
        return this.$color('downtime');
      }

      return seriesColor;
    },
    parseAggregate(a) {
      switch (a) {
        case 'd':
          return ['day'];
        case 'h':
          return ['hour'];
        case 'sd':
          return ['shift', 'day'];
        case 'm':
          return ['month'];
        case 'o':
          return ['order'];
        case 'w':
          return ['week'];
        default:
          return a;
      }
    },
  },
  created() {
    this.plantTime = this.plantNow(this.$now);
  },
};
</script>
