/* eslint-disable max-len */
export default {
  'rules': 'Regeln',
  'functions': 'Vorverarbeitungsfunktionen',
  hints: 'Hinweisregeln',
  'addRule': 'Füge Regel hinzu',
  'help': 'Hilfe',


  'operatorCockpit': 'Operator Ansicht',
  'weekStart': 'Wochen Start',
  'cockpitType': 'Ansicht Typ',
  'cockpitLayout': 'Ansicht Layout',
  'countingOEE': 'OEE eingeschlossen',
  'maxPerformance': 'Maximale Momentanleistung',
  'maxPerformanceInfo': 'Wird in der Zählerüberwachung verwendet, um zu validieren, ob der neue Zählerwert machbar ist (0 = Überwachung deaktiviert)',
  'excludeOEE': 'OEE ausgeschlossen',
  'thresholds': 'Schwellenwerte',
  'saved': 'gespeichert',
  'baseUnit': 'Grundeinheit',
  'baseOrderUnit': 'Standard Auftrags Einheit',
  'notSelected': 'nicht ausgewählt',
  'saveFailed': 'speichern fehlgeschlagen',
  'addMachine': 'Maschine zum Filter hinzufügen',
  'noCounter': 'Maschine hat keinen Zähler',
  'machineBaseUnitSetInCounter': 'Die Maschineneinheit wird durch die Einheit des Zählers bestimmt',
  'shortPerformancePeriod': 'Zeitraum',
  'setupIssues': 'Einrichtungsprobleme',
  'noIssuesDetected': 'Keine Konfigurationsprobleme festgestellt!',
  'test': 'Prüfen',
  'isDummy': 'Ist Attrappe',
  'isDummyInfo': 'Maschinenattrappen werden nicht überwacht und haben keine Signale. Sie können manuell als Ursache einer Ausfallzeit markiert werden.',
  'maxTimeBufferOffset': 'Stopp Verzögerung nach der vorgeschalteter Anlage',
  'maxTimeBufferOffsetInfo': 'Wenn die vorherige Anlage nicht mehr funktioniert, wie lange dauert es, bis diese Anlage stoppt?',
  'rootCauseDelay': 'Hauptursache Verzögerung',
  'rootCauseDelayInfo': 'Wie spät kann diese Anlage kaputt gehen, damit andere Anlagen immer noch auf diese als Ursache hinweisen?',
  'signalNameError': 'Der Signalname darf die Zeichen „(“ und „)“ nicht enthalten',

  'rule': {
    'rule': 'Regel',
    'condition': 'Zustand',
    'addAndCondition': 'und',
    'showAdvanced': 'Verwenden Sie den erweiterten Editor',
    'info': 'Der Maschinenzustand wird basierend auf den folgenden Regeln bestimmt. Wenn eine Signalwertänderung beobachtet wird,\\n analysiert die Regel-Engine die folgenden Regeln beginnend mit der höchsten Priorität.\\n Die erste Regel, die \'true\' zurückgibt, bestimmt den neuen Status',
    'ruleType': 'Regel Typ',
    'eventType': 'Einfache Bedingung',
    'cSharpType': 'C# Funktion',
    'timeBasedRule': 'Zeitbasierte Regel',
    'script': 'Script'
  },
  'events': {'forcedOnly': 'nur gezwungen', 'showSystem': 'Systemereignisse anzeigen'},
  'tooltipState': {
    'lack': 'Dieser Zustand tritt auf, wenn die Anlage nicht genügend Komponenten erhält, die für eine kontinuierliche Produktion benötigt werden. Das Problem kann ein defektes Förderband sein, oder Bediener, die die Komponenten nicht rechtzeitig nachfüllen, oder die Komponentenlagerung, die zu weit von der Linie entfernt ist.',
    'work': 'Dieser Zustand wird angezeigt, wenn die Anlage ordnungsgemäß funktioniert und der Produktionsprozess ausgeführt wird.',
    'inactive': 'Dies ist ein neutraler Zustand, den PackOS für die Anlage verwendet, wenn kein Fehler vorliegt, die Anlage jedoch nicht läuft.',
    'stop': 'Dieser Zustand zeigt an, dass die Anlage absichtlich von einem Bediener angehalten wurde, weil er diese beispielsweise konfigurieren oder reinigen will. Wenn sich die Anlage in diesem Zustand befindet, zeigt PackOS das Problem an, das aufgetreten ist, bevor der Status "Von Operator beendet" wirksam wurde.',
    'failure': 'Dieser Zustand weist darauf hin, dass an der Maschine eine Störung durch zB mechanischem Verschleiß oder Bedienungsfehlern aufgetreten ist. In diesem Fall ist die Ursache des Problems das Problem, das vor dem Auftreten des Fehlerstatus aufgetreten ist.',
    'off': 'Dieser Zustand wird angezeigt, wenn die Maschine vom Netz getrennt wird, beispielsweise weil der Strom ausgefallen ist oder das Gerät die Verbindung zum Treiber verloren hat',
    'idle': 'Dieser Zustand zeigt an, wenn Ihre Maschine nicht funktioniert, obwohl sie nicht defekt ist und kein Problem damit besteht. In diesem Fall sucht PackOS nach der Ursache des Problems, indem es den Upstream-Rechner überprüft',
    'nolivedata': 'Dies ist ein vorübergehender Zustand, der auftritt, wenn PackOS vorübergehend die Verbindung zum Werk verliert, beispielsweise aufgrund von Problemen mit der Internetverbindung. PackOS zeigt diesen Zustand nur temporär an und ändert ihn schnell in andere „operative“ Zustände',
    'holdup': 'Dieser Zustand tritt auf, wenn die Maschine nicht funktioniert, weil ihre Ausgabe blockiert ist, normalerweise von nachgeschalteten Maschinen. In diesem Fall sucht PackOS nach der Ursache des Problems, indem es die nachgeschaltete Maschine überprüft. Die Fehlerquelle in der nachgelagerten Maschine wird als Zustand und Problem für die Maschine mit dem Status Holdup übernommen.',
    'changeover': 'Dieser Zustand erscheint, wenn die Maschine gestoppt wird, weil entweder die Produktionslinie oder die Maschine auf einen neuen Produktionsprozess eingestellt ist (rüsten).'
  },
  'shortPerformance': 'Momentane Leistung',
  'shortPerformanceInfo': 'Momentane Leistung, berechnet als aktuelle durchschnittliche Produktionsleistung',
  'value': 'Wert',
  'priority': 'Priorität',
  'dayOfWork': 'Arbeitstag',
  'plantConfiguration': 'Anlagen Einstellungen',
  'machineSignals': 'Maschinensignale',
  'signals': 'Signale',
  'signalsExport': 'Signale exportieren',
  'logixGateConfigExport': 'LogixGate-Konfiguration exportieren',
  'signalsImport': 'Signale importieren',
  'workEventsImport': 'Probleme importieren',
  'csvImport': 'CSV Importieren',
  'csvExport': 'CSV Exportieren',
  'properties': 'Eigenschaften',
  'signalsAndProperties': 'Signale und Eigenschaften',
  'machineLicenses': 'Maschinenlizenzen',
  'addSignal': 'Füge Signale hinzu',
  'addProperty': 'füge Eigenschaften hinzu',
  'electricity': 'Elektrizität',
  'electricityBaseline': 'Basislinie Elektrizität',
  'electricityMax': 'Elektrizität durchschnittliches max.',
  'shiftDefinitions': 'Schicht Definitionen',
  'info': 'Information',
  'deleteInfo': 'Klicke "speichern" zum löschen',
  'timezone': 'Zeitzone',
  'yourCurrent': 'Ihr aktueller',
  'differentTimezoneInfo': 'Sie sind in einer anderen Zeitzone als die der Werksstandort',
  'differentTimezoneSettingsInfo': 'Die folgenden Einstellungen gelten für die Zeitzone des Werksstandortes',
  'differentTimezoneSelectorInfo': 'Wir zeigen die Zeit umgerechnet auf Ihre Zeitzone an',
  'differentialWaste': 'Unterschiedlicher Ausschuss',
  'wasteBaseDiffCounter': 'Basisanlage zur Berechnung des Ausschusses',
  'disableBufferResetBetweenOrders': 'Deaktivieren Sie das Zurücksetzen des Puffers zwischen Aufträgen',
  'workingHours': 'Arbetszeit',
  'addNewShift': 'füge neue Schicht hinzu',
  'productionExpectations': 'Produktionsziel',
  'doYouWantToDelete': 'Wollen Sie das löschen',
  'newShift': 'neue Schicht',
  'editShift': 'Schicht bearbeiten',
  'removeShift': 'Schicht entfernen',
  'users': 'Benutzer',
  'nothingToAdd': 'nichts hinzuzufügen',
  'attachedLinesAndMachines': 'angeschlossene Linien und Maschinen',
  'addLineOrMachine': 'füge Linie / Maschine hinzu',
  'layout': 'Layout',
  'saveLayout': 'Laout speichern',
  'elementsOrder': 'Elementreihenfolge',
  'advanced': 'erweiterte Einstellungen',
  'default': 'Standardeinstellungen',
  'noLayoutLine': 'Linie ist im Anlagenlayout nicht definiert',
  'units': 'Einheiten',
  'unitCode': 'Einheit Abkürzung',
  'isBaseUnit': 'Basiseinheit',
  'fromUnit': 'Child UoM',
  'toUnit': 'Parent UoM',
  'multiplier': 'Multiplikator',
  'shortName': 'kurzer Name',
  'blockCard': 'Card sperren',
  'activeSince': 'aktiv seit',
  'production': 'Produktion',
  'card': 'Card',
  'cardId': 'Card ID',
  'cardPin': 'Card PIN',
  'oldPin': 'vorheriger PIN',
  'newPin': 'neuer PIN',
  'pinChange': 'PIN änder',
  'pinLengthError': 'PIN muss genau 4 Nummern haben',
  'cardIdLengthError': 'Card ID muss mindestens 4 Zeichen haben',
  'connections': 'Produktionsflüsse',
  'displaySettings': 'Anzeige',
  'adminPlantSettings': 'Anlageneinstellungen verwalten',
  dataValidation: 'Datenvalidierung',
  importTemplates: 'Vorlagen für den Import',
  'orderAutostartThreshold': 'Auftrag automatischer Start',
  'forcedStateThreshold': 'Beenden der erzwungenen Stillstandzeit',
  'downtimeThreshold': 'Beenden der laufenden Stillstandzeit',
  'minorStopThreshold': 'Schwellenwert einer Kleinstörung',
  'designPerformanceInfo': 'die erreichbare Maschinenleistung laut Hersteller',
  'validatedPerformanceInfo': 'die Maschinenleistung, die unter realen Bedingungen gemessen und bestätigt wurde',
  'orderAutostartThresholdInfo': 'Produzierte Menge, die erforderlich ist, um einen Auftrag automatisch zu starten',
  'forcedStateThresholdInfo': 'Produzierte Menge, die erforderlich ist, um die erzwungene Stillstandzeit automatisch zu beenden',
  'downtimeThresholdInfo': 'Produzierte Menge, die erforderlich ist, um die andauernde Stillstandzeit automatisch zu beenden',

  'calculateEnergyFromMachines': 'Energie von Maschinen berechnen',
  'expectations': {
    'expectedOee': 'erwartete OEE',
    'expectedPerformance': 'erwartete Leistung',
    'maxExpectedFailures': 'erwartete maximale Anzahl an Fehlern',
    'validatedPerformance': 'Validierte Leistung',
    'designPerformance': 'Geplante Leistung'
  },
  'automation': {
    'automation': 'Automatisierung',
    'add': 'Automatisierung hinzu fügen',
    'type': {
      'CreateQuestOnWorkEvent': {
        'title': 'Aufgabe erstellen',
        'description': 'Aufgabe erstellen, wenn diese Art von Problem auf der Maschine auftritt'
      },
      'CreateAndCloseQuestOnWorkEvent': {
        'title': 'Aufgabe erstellen und schließen',
        'description': 'Aufgabe erstellen, wenn diese Art von Problem an der Maschine auftritt, und es automatisch schließen, wenn das Problem aufhört'
      }
    }
  },
  'taxonomy': {
    'taxonomy': 'Taxonomie'
  },
  'unit': {
    'usedInPackageStructure': 'Entfernen der Einheit nicht möglich, sie wird in einer Paketstrukturdefinition verwendet',
    'fieldsNotValid': 'Falsches Zellenformat'
  },
  'sku': {
    'expectedEffort': 'Erwarteter Aufwand (Personenstunden: mh)',
    'skuDescriptionTooltip': 'Wenn leer gelassen, wird die SKU-Beschreibung verwendet'
  },
  'function': {
    'sessionName': 'Sitzungsname',
    'sessionInfo': 'Funktionen, die denselben Sitzungsnamen verwenden, werden sequentiell (und nicht gleichzeitig) ausgeführt.',
    'run': 'starten',
    'testValue': 'Testwert',
    'testResult': 'Testergebnis',
    'script': 'Skript',
    'isStateful': 'Zustand',
    'sequenceProcessing': 'Folgende Tags sequentiell verarbeiten',
    'newFunction': 'Funktion hinzufügen',
    'editFunction': 'Funktion bearbeiten',
    'triggers': 'Auslöser'
  },
  'energy': 'Energie',
  'setUrl': 'Neue URL speichern',
  'authUrl': 'Authentifizierungs-URL-Adresse',
  'loadedOnNextLogin': 'Wird nach der nächsten Anmeldung geladen',
  'allTypes': 'Alle Typen'
};
