<template>
  <div>
    <h5
      v-if="!hideHeader"
      class="mb-4"
    >
      <i class="far fa-clipboard mr-1" />
      {{ $t('activities.actions.productionReconciliation') }}
    </h5>

    <Order
      v-if="!hideOrder"
      v-bind="order"
      selected
      :hoverable="false"
    />

    <ProductionReconciliationForm
      class="mt-3"
      :activity-id="order.id"
      :order-unit="order.producedQuantity.unit"
      :order-done="order.producedQuantity.value"
      :order-waste="order.wastedQuantity.value"
      :order-begin-date="order.actualExecution.begin"
      :order-end-date="order.actualExecution.end"
      :order-line-id="order.lineId"
      :read-only="readOnly"
      :order-finish="orderFinish"
      @complete="$emit('complete')"
      @hide="$emit('hide')"
    />
  </div>
</template>

<script>
import ProductionReconciliationForm
  from '@/components/activity/productionReconciliation/ProductionReconciliationForm';
import Order from '@/components/order/single/Order';

export default {
  name: 'ProductionReconciliation',
  props: {
    order: {
      type: Object,
      required: true,
    },
    readOnly: Boolean,
    hideOrder: Boolean,
    hideHeader: Boolean,
    orderFinish: Boolean,
  },
  components: {
    Order,
    ProductionReconciliationForm,
  },
};
</script>

<style scoped lang="scss">

</style>
