<template>
  <form
    class="h-100"
    @submit.prevent="false"
  >
    <div
      class="flex-grow-1"
      style="padding: 10px 20px"
    >
      <div
        v-if="summary"
        class="refresh-date pt-3 pr-3"
      >
        <button
          class="btn btn-outline-secondary btn-sm"
          type="button"
          @click="request"
        >
          <i
            class="fas fa-sync"
            :class="dataPending ? 'fa-spin' : ''"
          />
          <span
            v-if="summary"
            class="pl-2"
          >
            {{ $t('general.lastRefresh', [formatDate(lastRefresh)]) }}
          </span>
        </button>
      </div>
      <div>
        <OrderList
          v-if="orderData"
          class="pt-3 order-preview"
          :list="[orderData]"
          :selected-id="orderData.id"
          :hide-element="false"
          :show-actions="false"
          :show-button="true"
        />
        <div v-else>
          <Loader />
        </div>
      </div>

      <div>
        <OrderCounterSummary
          :summary="summary"
          :order-id="order.id"
        />
      </div>

      <div class="mt-2 mx-2">
        <div v-if="error && !resources">
          <div
            v-if="error"
            class="alert alert-danger d-flex align-items-center"
          >
            <i class="fas fa-exclamation-triangle mr-2" />
            <p class="mb-0">
              {{ error.detail }}
            </p>
          </div>
        </div>
        <div
          v-if="!resources"
          class="d-flex justify-content-between"
        >
          <div>
            <div class="w-175 px-2">
              <button
                class="btn btn-block btn-outline-secondary btn-sm"
                type="button"
                @click="resources = true"
              >
                <i class="far fa-clipboard pr-1" />
                {{ $t('activities.summary.manualReconciliation') }}
              </button>
            </div>
          </div>

          <div
            class="d-flex justify-content-between"
            style="width: 350px"
          >
            <div class="w-50 px-2">
              <button
                class="btn btn-block btn-secondary btn-sm"
                type="button"
                @click="$emit('close')"
              >
                {{ $t('general.cancel') }}
              </button>
            </div>
            <div class="w-50 px-2">
              <button
                class="btn btn-block btn-primary btn-sm"
                :disabled="pending"
                @click="complete()"
              >
                {{ $t('activities.actions.finishOrder') }}
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            v-if="error"
            class="alert alert-danger d-flex align-items-center"
          >
            <i class="fas fa-exclamation-triangle mr-2" />
            <p class="mb-0">
              {{ error.detail }}
            </p>
          </div>
          <ActivityResources
            v-if="!pending"
            :order="order"
            :activity-id="order.id"
            :manual-summary="true"
            :scope-start="order.actualExecution.begin"
            :line-id="order.lineId"
            :activity-type="order.type"
            :order-unit="order.orderedQuantity.unit"
            :action-name="$t('activities.actions.finishOrder')"
            :disabled="pending"
            :order-production="summary && summary.order ? summary.order.accepted.goodCount : 0"
            :order-waste="summary && summary.order ? summary.order.accepted.wasteCount : 0"
            order-finish
            @cancel="resources = false"
            @complete="complete(true)"
            @prodDone="done = $event"
            @prodWasted="wasted = $event"
            @refresh="request"
            @hide="resources = false"
          />
          <div
            v-else
            class="text-center"
          >
            <Loader />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import calcDuration from '@/utils/calcDuration';
import OrderList from '@/components/order/OrderList';
import OrderCounterSummary from '@/components/order/OrderCounterSummary';
import ActivityResources from '@/components/activity/ActivityResources';

export default {
  props: {
    order: Object,
  },
  data: () => ({
    productionUpdates: null,
    resources: false,
    pending: false,
    dataPending: false,
    error: null,
    refresh: true,
    done: 0,
    wasted: 0,
    summary: null,
    now: null,
    lastRefresh: null,
    orderData: null,
  }),
  components: {
    ActivityResources,
    OrderList,
    OrderCounterSummary,
  },
  computed: {
    ...mapGetters(['plantId']),
  },
  methods: {
    ...mapActions([
      'completeOrder',
      'getOrderCountersSummary',
      'getOrder',
    ]),
    formatDate(date) {
      return calcDuration(moment(date).unix(), this.now);
    },
    complete(forceManualOverride = false) {
      this.pending = true;
      this.error = null;

      const fourteenDaysInSeconds = 14 * 24 * 60 * 60;
      const end = this.order.actualExecution.end
        ? moment(this.order.actualExecution.end).unix()
        : this.$now;
      const duration = end - moment(this.order.actualExecution.begin).unix();

      let manualOverride = true;
      if (duration >= fourteenDaysInSeconds && !forceManualOverride) {
        manualOverride = false;
      }

      this.completeOrder({
        params: {
          orderId: this.order.id,
          query: {
            plantId: this.plantId,
          },
        },
        data: {
          manualOverride: manualOverride || undefined,
          done: this.done,
          wasted: this.wasted,
        },
      })
        .then(() => {
          this.$emit('completed');
        })
        .catch(({ response: { data } }) => {
          this.error = data;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    request() {
      this.dataPending = true;

      this.done = this.order.producedQuantity.value;
      this.wasted = this.order.wastedQuantity.value;

      const summary = this.getOrderCountersSummary({
        params: {
          plantId: this.plantId,
          query: {
            orderId: this.order.id,
          },
        },
      })
        .then(({ data }) => {
          this.summary = data;
          this.done = this.summary.order ? this.summary.order.accepted.goodCount : 0;
          this.wasted = this.summary.order ? this.summary.order.accepted.wasteCount : 0;
        });

      const order = this.getOrder({
        params: {
          orderId: this.order.id,
          query: { plantId: this.plantId },
        },
      })
        .then(({ data }) => {
          this.orderData = data;
        });

      Promise.all([summary, order])
        .finally(() => {
          this.dataPending = false;
          this.lastRefresh = this.$now * 1000;
        });
    },
  },
  created() {
    this.now = this.$now;
    this.request();
    this.dateLoop = setInterval(() => {
      this.now = this.$now;
    }, 1000);
  },
  destroyed() {
    clearInterval(this.dateLoop);
  },
};
</script>

<style lang="scss" scoped>
  .wrapper {
    height: 100vh;
    width: 100%;
    background-color: rgba(#C4C4C4, 0.2);
  }

  .refresh-date {
    font-size: 12px;
    color: #888;
    padding-left: 10px;
    text-align: right;
    margin-bottom: -10px;
  }
</style>
