/* eslint-disable max-len */
export default {
  users: 'Użytkownicy',
  functions: 'Wstępne przetwarzanie',
  hints: 'Konfiguracja podpowiedzi',
  rules: 'Reguły',
  help: 'Pomoc',
  inputName: 'Nazwa połączenia',
  tag: 'Tag',
  thresholds: 'Progi',
  saved: 'Zapisano',
  baseUnit: 'Bazowa jednostka',
  baseOrderUnit: 'Domyślna jednostka zlecenia',
  notSelected: 'Nie Wybrano',
  saveFailed: 'Zapis Nieudany',
  addRule: 'Dodaj regułę',
  countingOEE: 'Liczenie OEE',
  maxPerformance: 'Maksymalna chwilowa wydajność',
  maxPerformanceInfo: 'Wydajność używana do sprawdzenia czy nowa wartość licznika jest realna (0 = walidacja wyłączona)',
  operatorCockpit: 'Kokpit operatora',
  weekStart: 'Początek tygodnia',
  cockpitType: 'Typ kokpitu',
  cockpitLayout: 'Układ kokpitu',
  excludeOEE: 'Nie uwzględniaj OEE',
  addMachine: 'Dodaj maszynę do filtrów',
  noCounter: 'Maszyna nie ma licznika',
  machineBaseUnitSetInCounter: 'Jednostkę maszyny wyznacza jej licznik',
  shortPerformancePeriod: 'Okres',
  setupIssues: 'Błędy konfiguracyjne',
  noIssuesDetected: 'Nie znaleźliśmy błędów konfiguracyjnych!',
  test: 'Przetestuj',
  isDummy: 'Jest dummy',
  isDummyInfo: 'Maszyny dummy nie są automatycznie monitorowane i nie są przypisane do nich sygnały. Możesz jednak ręcznie wskazać taką maszynę jako przyczynę źródłową problemu.',
  maxTimeBufferOffset: 'Czas zatrzymania po poprzedniej maszynie',
  maxTimeBufferOffsetInfo: 'Jeśli poprzednia maszyna przestała pracować, ile czasu potrzeba aż ta się zatrzyma?',
  rootCauseDelay: 'Opóźnione źródło problemu',
  rootCauseDelayInfo: 'Po zatrzymaniu linii, jak późno ta maszyna może się zatrzymać żeby wciąż być wskazana jako źródło problemu?',
  signalNameError: 'Nazwa sygnału nie może zawierać znaków "(" i ")"',
  dataSource: 'Źródło danych',
  edgeDevice: 'Urządzenie brzegowe',
  timeSourceHeader: 'Źródło czasu',
  timeSource: {
    origin: 'Czas serwera OPC',
    edgeDevice: 'Czas urządzenia brzegowego',
    iotHub: 'Czas otrzymania wiadomości przez IoT Hub',
    queue: 'Czas zarejestrowania wiadomości na kolejce',
  },
  timeOffset: 'Korekta czasu w sekundach',
  timeOffsetNegativeValues: 'ujemne wartości cofają zegar',
  timeOffsetHeader: 'Opóźnienie zegara',
  rule: {
    rule: 'Reguła',
    condition: 'Warunek',
    addAndCondition: 'ORAZ',
    showAdvanced: 'Pokaż zaawansowany edytor',
    info: 'Stany maszyn są klasyfikowane według reguł poniżej. Gdy zaobserwujemy zmianę wartości sygnału, silnik reguł analizuje poniższe warunki zaczynając od najwyższego priorytetu. Pierwsza reguła która zwróci \'prawdę\' wyznaczy nowy stan',
    ruleType: 'Rodzaj reguły',
    eventType: 'Prosty warunek',
    cSharpType: 'Funkcja C#',
    timeBasedRule: 'Reguła czasowa',
    script: 'Skrypt',
  },
  events: {
    forcedOnly: 'Tylko wymuszone',
    notAssignedEvents: 'Nie przypisane',
    showSystem: 'Pokaż systemowe',
  },
  tooltipState: {
    lack: 'Ten stan pojawia się, gdy Twoja maszyna nie otrzymuje wystarczającej ilości komponentów potrzebnych do zapewnienia jej ciągłej pracy. Problemem może tu być niesprawny taśmociąg, operatorzy, którzy nie uzupełnili komponentów na czas lub umiejscowienie magazynu komponentów zbyt daleko od linii produkcyjnej',
    work: 'Ten stan pojawia się, gdy Twoja maszyna pracuje poprawnie a produkcja przebiega prawidłowo',
    inactive: 'Jest to neutralny stan, który PackOS wyświetla dla Twojej maszyny, gdy ta nie pracuje choć jest sprawna i nie uległa awarii',
    stop: 'Ten stan wskazuje, że Twoja maszyna została celowo zatrzymana przez pracownika, by mogła zostać skonfigurowana lub wyczyszczona. Gdy Twoja maszyna znajduje się w stym stanie, PackOS wyświetla problem, który wystąpił przed tym, jak maszyna zostala wprowadzona w stan Zatrzymania przez Operatora',
    failure: 'Ten stan wskazuje, że Twoja maszyna została zatrzymana lub nie działa prawidłowo z powodu, na przykład, zużycia lub błędu operatora. W tym przypadku źródłem problemu jest problem, który wystąpił zanim pojawił się stan Awarii',
    off: 'Ten stan pojawia się, gdy nie ma połączenia z Twoją maszyną z powodu, na przykład odłączenia jej od zasilania lub utraty połączenia ze sterownikiem',
    idle: 'Ten stan pojawia się, gdy Twoja maszyna nie pracuje, chociaż nie jest zepsuta i nie ma z niąjakiegokolwiek problemu. W tym przypadku PackOS poszukuje źródła problemu poprzez sprawdzanie maszynpoprzedzających maszynę, dla której pojawił się stan Gotowość',
    nolivedata: 'Jest to tymczasowy stan, który pojawia się, gdy PackOS chwilowo straci połączenie z fabrykąz powodu, na przykład, problemów z połączeniem internetowym. PackOS pokazuje ten stan tylko przez chwilęi szybko zmienia go na inny stan',
    holdup: 'Ten stan pojawia się, gdy Twoja maszyna nie pracuje ponieważ jej wyjście jest zablokowane,zazwyczaj z powodu dalszych maszyn, które następują po maszynie, dla której wyświetlił się stan Zatoru.PackOS sprawdza źródło problemu poprzez sprawdzenie maszyn następujących. Źródło problemu z maszyny następującejjest wtedy kopiowane zarówno jako stan, jak i problem maszyny w stanie Zatoru',
    changeover: 'Ten stan pojawia się, gdy Twoja maszyna została zatrzymana w związku z przystosowaniem jej do nowegoprocesu produkcyjnego (przezbrojenie)',
  },
  shortPerformance: 'Chwilowa wydajność',
  shortPerformanceInfo: 'Chwilowa wydajność liczona jako średnia z produkcji z ostatnich kilku minut',
  value: 'Wartość',
  priority: 'Priorytet',
  dayOfWork: 'Dzień pracy',
  plantConfiguration: 'Konfiguracja fabryki',
  machineSignals: 'Sygnały maszyn',
  signals: 'Sygnały',
  signalsExport: 'Eksportuj sygnały',
  logixGateConfigExport: 'Eksportuj konfigurację LogixGate',
  signalsImport: 'Importuj sygnały',
  workEventsImport: 'Importuj problemy',
  csvImport: 'Importuj CSV',
  csvExport: 'Eksportuj CSV',
  properties: 'Właściwości',
  signalsAndProperties: 'Sygnały i właściwości',
  machineLicenses: 'Licencje maszyn',
  addSignal: 'Dodaj sygnał',
  addProperty: 'Dodaj właściwość',
  electricity: 'Prąd',
  electricityBaseline: 'Średni pobór prądu',
  electricityMax: 'Maksymalny średni pobór prądu',
  shiftDefinitions: 'Definicje zmian',
  info: 'Info',
  deleteInfo: 'Naciśnij przycisk \'Zapisz\' aby usunąć',
  timezone: 'Strefa czasowa',
  yourCurrent: 'Twoja obecna',
  differentTimezoneInfo: 'Jesteś w innej strefie czasowej niż fabryka!',
  differentTimezoneSettingsInfo: 'Poniższe ustawienia dotyczą docelowej strefy czasowej',
  differentTimezoneSelectorInfo: 'Będziemy wyświetlać czas przeliczony na twoją strefę czasową',
  differentialWaste: 'Straty różnicowe',
  wasteBaseDiffCounter: 'Maszyna względem, której liczone są straty różnicowe',
  disableBufferResetBetweenOrders: 'Nie resetuj bufora pomiędzy zleceniami',
  workingHours: 'Godziny pracy',
  addNewShift: 'Dodaj zmianę',
  productionExpectations: 'Cele produkcyjne',
  doYouWantToDelete: 'Czy chcesz usunąć',
  newShift: 'Nowa zmiana',
  editShift: 'Edycja zmiany',
  removeShift: 'Usuń zmianę',
  nothingToAdd: 'Nie ma nic do dodania',
  attachedLinesAndMachines: 'Przypisane linie i maszyny',
  addLineOrMachine: 'Dodaj linię / maszynę',
  layout: 'Plan',
  saveLayout: 'Zapisz ułożenie',
  elementsOrder: 'Kolejność elementów',
  advanced: 'Zaawansowane',
  default: 'Domyślne',
  noLayoutLine: 'Linia nie jest zdefiniowana na planie fabryki',
  units: 'Jednostki',
  unitCode: 'Kod jednostki',
  isBaseUnit: 'Jednostka bazowa',
  fromUnit: 'Child UoM',
  toUnit: 'Parent UoM',
  multiplier: 'Mnożnik',
  shortName: 'Krótka nazwa',
  blockCard: 'Zablokuj kartę',
  activeSince: 'Aktywna od',
  production: 'Produkcja',
  card: 'Karta',
  cardId: 'Numer karty',
  cardPin: 'PIN karty',
  oldPin: 'Poprzedni PIN',
  newPin: 'Nowy PIN',
  pinChange: 'Zmień PIN',
  pinLengthError: 'PIN musi mieć 4 cyfry',
  cardIdLengthError: 'Numer karty musi mieć conajmniej 4 znaki',
  connections: 'Przepływy',
  displaySettings: 'Wyświetlanie',
  adminPlantSettings: 'Ustawienia fabryk',
  dataValidation: 'Walidacja danych',
  importTemplates: 'Szablony importu',
  orderAutostartThreshold: 'Start zlecenia automatycznie',
  forcedStateThreshold: 'Zakończenie wymuszonego przestoju',
  downtimeThreshold: 'Blokada trwającego przestoju',
  minorStopThreshold: 'Próg określający przestój jako mikroprzestój',
  designPerformanceInfo: 'Wydajność maszyny jaka może być osiągnięta wg. założeń producenta maszyny',
  validatedPerformanceInfo: 'Wydajność maszyny jaka została zmierzona i zwalidowana w warunkach rzeczywistych',
  orderAutostartThresholdInfo: 'Próg okreslający po jakiej ilości produkcji zlecenie ma wystartować automatycznie',
  forcedStateThresholdInfo: 'Próg określający po jakiej ilości produkcji ma być zakończony wymuszony przestój automatycznie',
  downtimeThresholdInfo: `Próg okreslający po jakiej ilości produkcji można zakończyć trwający przestój.
    Jeśli zadana ilość produkcji nie zostanie zarejestrowana, trwający przestój nie zmieni ani nie skończy się`,
  calculateEnergyFromMachines: 'Licz energię z maszyn',
  expectations: {
    expectedOee: 'Oczekiwane OEE',
    expectedPerformance: 'Oczekiwana wydajność',
    maxExpectedFailures: 'Oczekiwana maksymalna liczba awarii',
    validatedPerformance: 'Zmierzona wydajność',
    designPerformance: 'Nominalna wydajność',
  },
  automation: {
    add: 'Dodaj automatyzację',
    automation: 'Automatyzacja',
    type: {
      CreateQuestOnWorkEvent: {
        title: 'Utwórz zadanie',
        description: 'Utwórz zadanie jeśli ten typ problemu wystąpi na maszynie',
      },
      CreateAndCloseQuestOnWorkEvent: {
        title: 'Utwórz i zamknij zadanie',
        description: 'Utwórz zadanie jeśli ten typ problemu wystąpi na maszynie i zamknij je automatycznie gdy problem zniknie',
      },
    },
  },
  unit: {
    usedInPackageStructure: 'Usunięcie jednostki nie jest możliwe, gdy jest ona używana w jakiejś strukturze pakowania',
    fieldNotValid: 'Błędny format pola',
  },
  sku: {
    expectedEffort: 'Pracochłonność (mh)',
    skuDescriptionTooltip: 'Jeśli pole pozostanie puste, zostanie użyty opis jednostki SKU',
  },
  function: {
    sessionName: 'Nazwa sesji',
    sessionInfo: 'Funkcje używające tej samej nazwy sessji będą wykonywane sekwencyjnie (a nie równolegle)',
    run: 'Run',
    testValue: 'Testowa wartość',
    testResult: 'Rezultat testu',
    triggers: 'Triggery',
    trigger: 'Trigger',
    isStateful: 'Przechowuje dane',
    script: 'Skrypt',
    newFunction: 'Dodaj funkcję',
    editFunction: 'Edytuj funkcję',
    executions: 'Historia uruchomień',
    logs: 'Logi',
    processed: 'Przetworzono',
    sourceTimestamp: 'Czas triggera',
  },
  energy: 'Energia',
  setUrl: 'Zapisz nowy adres URL',
  authUrl: 'Adres URL autoryzacji',
  loadedOnNextLogin: 'Zostaną pobrane po zalogowaniu',
  allTypes: 'Wszystkie typy',
  cameras: {
    location: 'Lokalizacja',
    startDownload: 'Start pobierania',
    endDownload: 'Koniec pobierania',
    before: 'od',
    after: 'po',
    startOfTheDowntime: 'początku przestoju',
    addCamera: 'Dodaj kamerę',
    editCamera: 'Edytuj kamerę',
    cameraName: 'Nazwa kamery',
    cameraDescription: 'Opis kamery',
    assignToMachine: 'Przypisz do linii / maszyny',
    edgeDevice: 'Urządzenie brzegowe',
    diskSize: 'Wielkość dysku',
    automaticallyDownloadVideoClip: 'Automatycznie pobieraj klipy wideo',
    starting: 'Zaczynając od',
    ending: 'Kończąc na',
  },
  edgeDevices: {
    edgeDevice: 'Urządzenie brzegowe',
    addEdgeDevice: 'Dodaj urządzenie brzegowe',
    edgeDeviceName: 'Podaj nazwę urządzenia',
    enterName: 'Podaj nazwę',
    chooseDeviceType: 'Wybierz typ urządzenia',
    timeSource: 'Źródło czasu',
    deleteThisEdgeDevice: 'Czy chcesz usunąć to urządzenie brzegowe?',
    noEdgeDevices: 'Brak urządzeń brzegowych',
    recommendedName: 'Zalecana nazwa',
    readFromIoTHub: 'Odczytaj z IoT Hub',
    notRegisteredIoTHub: 'Urządzenie nie zarejestrowane w IoT Hub',
    registeredIoTHub: 'Zarejestrowane w IoT Hub',
    createConnectionStrings: 'Wygeneruj dane do podłączenia',
    createConnectionStringsInfo: 'Zarejestrujemy taki \'Device\' w IoT Hub i wygenerujemy klucze które umożliwią ci podłączenie instancji LogiX Gate',
    showConnectionStrings: 'Pokaż dane do podłączenia',
    connections: 'Połączenia',
    readReportedConnections: 'Odśwież dostępne połączenia',
    samplingInterval: 'Częstotliwość próbkowania',
    discoverTags: 'Szukaj tagów',
    nonOpcConnectionEdit: 'Możesz edytować wyłączenie połączenia OPC UA',
    unableToRunNodeDiscovery: 'Przeszukiwanie serwera OPC UA nie powiodło się',
    updateStatus: {
      released: 'Konfiguracja aktualna',
      dirty: 'W trakcie aktualizacji...',
      deleted: 'Połączenie nie istnieje',
    },
    tagSource: 'Źródło'
  },
  customLayoutInfo: 'Wyświetl tą maszynę w układzie własnej konfiguracji.',
};
