<template>
  <div class="text-widget">
    <Gauge
      :key="rawDetails.width + rawDetails.height"
      :value="gaugeValue"
      :max="gaugeMax"
      :baseline="gaugeMin"
      :warning-from="gaugeWarning"
      :height="gaugeHeight"
      :width="gaugeWidth"
      class="mx-auto"
      style="position: relative; top: 5px"
    />

    <div
      class="value text-ellipsis px-1 text-center"
      :class="{
        'mb-2': doubleHeight,
      }"
    >
      <span v-if="value.type === customLayoutWidgetFieldType.static">
        {{ value.value || '-' }}
      </span>
      <span v-if="value.type === customLayoutWidgetFieldType.tag">
        {{ getTagValueForTag(value.value) }}
      </span>

      <span class="unit text-ellipsis">
        <span v-if="unit.type === customLayoutWidgetFieldType.static">
          {{ unit.value || '-' }}
        </span>
        <span v-if="unit.type === customLayoutWidgetFieldType.tag">
          {{ getTagValueForTag(unit.value) }}
        </span>
      </span>
    </div>

    <div class="title text-ellipsis w-100 px-1 text-center">
      {{ rawDetails.title || '-' }}
    </div>
  </div>
</template>

<script>
import { customLayoutWidgetFieldType } from '@/components/customLayout/customLayoutDictionary';
import Gauge from '@/components/charts/power/Gauge.vue';

export default {
  name: 'CustomLayoutGaugeWidget',
  props: {
    unit: Object,
    value: Object,
    minValue: Object,
    maxValue: Object,
    warningValue: Object,
    rawDetails: Object,

    currentTags: Array,
  },
  components: { Gauge },
  computed: {
    customLayoutWidgetFieldType() {
      return customLayoutWidgetFieldType;
    },
    gaugeValue() {
      if (!this.value) {
        return 0;
      }
      const value = this.value.type === customLayoutWidgetFieldType.tag
        ? this.getTagValueForTag(this.value.value)
        : this.value.value;
      return Number(value) || 0;
    },
    gaugeWarning() {
      if (!this.warningValue) {
        return 0;
      }
      const warningValue = this.warningValue.type === customLayoutWidgetFieldType.tag
        ? this.getTagValueForTag(this.warningValue.value)
        : this.warningValue.value;
      return Number(warningValue) || 0;
    },
    gaugeMax() {
      if (!this.maxValue) {
        return 0;
      }
      const maxValue = this.maxValue.type === customLayoutWidgetFieldType.tag
        ? this.getTagValueForTag(this.maxValue.value)
        : this.maxValue.value;
      return Number(maxValue) || 0;
    },
    gaugeMin() {
      if (!this.minValue) {
        return 0;
      }
      const minValue = this.minValue.type === customLayoutWidgetFieldType.tag
        ? this.getTagValueForTag(this.minValue.value)
        : this.minValue.value;
      return Number(minValue) || 0;
    },
    doubleHeight() {
      return this.rawDetails.size.h === 2;
    },
    gaugeHeight() {
      if (this.doubleHeight) {
        return 65;
      }
      return 30;
    },
    gaugeWidth() {
      if (this.doubleHeight) {
        return 80;
      }
      return 50;
    },
  },
  methods: {
    getTagValueForTag(tag) {
      return this.currentTags.find(t => t.nodeId === tag)?.value || '-';
    },
  },
};
</script>

<style scoped lang="scss">
.text-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .value {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: -4px;
  }

  .unit {
    font-size: 11px;
    font-weight: 400;
    opacity: 0.7;
  }

  .title {
    font-size: 12px;
  }
}
</style>
