<template>
  <div v-if="parsedDowntime">
    <div
      class="summary-card"
      :class="{
        'summary-card--big': showRootCause && !isLine,
        'summary-card--clickable': canEditDowntimes,
      }"
      @click="canEditDowntimes ? navigate('lineMachine') : null"
    >
      <div class="w-100">
        <div v-if="!isLine || (isLine && !parsedDowntime.rootWorkLog)">
          <p class="mb-0 font-weight-bold">
            {{ $t('work.downtime') }}:
          </p>

          <div>
            <StateBadge
              :state="parsedDowntime.workState"
              class="mr-2"
            />
            <p
              class="mb-0"
              :class="{
                'd-inline-block': showRootCause
              }"
            >
              <span class="font-weight-bold">
                [ {{ parsedDowntime.machineName }} ]
              </span>

              <span
                :style="{
                  opacity: workEventDeleted ? 0.5 : 1,
                }"
              >
                {{ parsedDowntime.mainDescription }}
              </span>

              <span
                v-if="workEventDeleted"
                class="badge badge-pill badge-secondary ml-1"
                style="background-color: #F2F2F2; color: #848484;"
              >
                <i class="fas fa-trash-can mr-1" />
                {{ $t('general.deleted') }}
              </span>
            </p>
          </div>

          <div
            v-if="getTaxonomyBindingPath(parsedDowntime.workEvent.id)"
            class="mt-1"
          >
            <span class="badge badge-pill badge-light text-black-50 text-ellipsis mw-100 overflow-hidden">
              <i class="fas fa-sitemap mr-1" />
              {{
                workEventTaxonomy.description
              }} / {{ getTaxonomyBindingPath(parsedDowntime.workEvent.id) }}
            </span>
          </div>
        </div>

        <hr v-if="showRootCause && !isLine">

        <div
          v-if="showRootCause && isLine"
          class="d-flex align-items-center justify-content-between"
        >
          <div>
            <p class="mb-0 font-weight-bold">
              {{ $t('work.rootCause') }}:
            </p>
            <StateBadge :state="parsedDowntime.rootWorkLog.workState" />
            <p class="mb-0">
              <span class="mr-1">
                →
              </span>
              <span class="font-weight-bold">
                [ {{ parsedDowntime.rootMachineName }} ]
              </span>

              <span
                :style="{
                  opacity: rootWorkEventDeleted ? 0.5 : 1,
                }"
              >
                {{ parsedDowntime.rootDescription }}
              </span>

              <span
                v-if="rootWorkEventDeleted"
                class="badge badge-pill badge-secondary ml-1"
                style="background-color: #F2F2F2; color: #848484;"
              >
                <i class="fas fa-trash-can mr-1" />
                {{ $t('general.deleted') }}
              </span>
            </p>

            <div
              v-if="getTaxonomyBindingPath(parsedDowntime.rootWorkLog.eventId)"
              class="mt-1 w-100 overflow-hidden"
            >
              <span class="badge badge-pill badge-light text-black-50 text-ellipsis mw-100 overflow-hidden">
                <i class="fas fa-sitemap mr-1" />
                {{
                  workEventTaxonomy.description
                }} / {{ getTaxonomyBindingPath(parsedDowntime.rootWorkLog.eventId) }}
              </span>
            </div>
          </div>

          <div
            v-if="canEditDowntimes"
            class="change-button"
          >
            {{ $t('general.change') }}
          </div>
        </div>

        <div
          v-if="showRootCause && !isLine"
          class="d-flex align-items-center justify-content-between"
        >
          <div>
            <p class="mb-0 font-weight-bold">
              {{ $t('work.rootCause') }}:
            </p>
            <p class="mb-0">
              <span class="mr-1">
                →
              </span>
              <span class="font-weight-bold">
                [ {{ parsedDowntime.rootMachineName }} ]
              </span>
              <StateBadge :state="parsedDowntime.rootWorkLog.workState" />

              <span
                :style="{
                  opacity: rootWorkEventDeleted ? 0.5 : 1,
                }"
              >
                {{ parsedDowntime.rootDescription }}
              </span>

              <span
                v-if="rootWorkEventDeleted"
                class="badge badge-pill badge-secondary ml-1"
                style="background-color: #F2F2F2; color: #848484;"
              >
                <i class="fas fa-trash-can mr-1" />
                {{ $t('general.deleted') }}
              </span>
            </p>

            <div
              v-if="getTaxonomyBindingPath(parsedDowntime.rootWorkLog.eventId)"
              class="mt-1"
            >
              <span class="badge badge-pill badge-light text-black-50 text-ellipsis mw-100 overflow-hidden">
                <i class="fas fa-sitemap mr-1" />
                {{
                  workEventTaxonomy.description
                }} / {{ getTaxonomyBindingPath(parsedDowntime.rootWorkLog.eventId) }}
              </span>
            </div>
          </div>
          <div
            v-if="canEditDowntimes"
            class="change-button"
          >
            {{ $t('general.change') }}
          </div>
        </div>
      </div>

      <div
        v-if="!parsedDowntime.rootWorkLog && canEditDowntimes"
        class="change-button"
        @click="canEditDowntimes ? navigate('lineMachine') : null"
      >
        {{ $t('general.change') }}
      </div>
    </div>

    <div
      class="summary-card"
      :class="{'summary-card--clickable': canEditDowntimes}"
      @click="canEditDowntimes ? navigate('reason') : null"
    >
      <div>
        <p class="mb-0 font-weight-bold">
          {{ $t('downtimeSelector.reason') }}:
        </p>
        <p class="mb-0">
          <span v-if="parsedDowntime.reasonDescription">
            {{ decodeURIComponent(parsedDowntime.reasonDescription) }}
          </span>
          <span
            v-else
            class="text-black-50"
          >
            {{ $t('downtimeSelector.noReason') }}
          </span>
        </p>
      </div>

      <div
        v-if="canEditDowntimes"
        class="change-button"
      >
        {{ $t('general.change') }}
      </div>
    </div>

    <div
      class="summary-card"
      :class="{'summary-card--clickable': canEditDowntimes}"
      @click="canEditDowntimes ? navigate('correctiveAction') : null"
    >
      <div>
        <p class="mb-0 font-weight-bold">
          {{ $t('work.actionTaken') }}:
        </p>
        <p class="mb-0">
          <span v-if="parsedDowntime.correctiveActionDescription">
            {{ decodeURIComponent(parsedDowntime.correctiveActionDescription) }}
          </span>
          <span
            v-else
            class="text-black-50"
          >
            {{ $t('downtimeSelector.noCorrectiveAction') }}
          </span>
        </p>
      </div>

      <div
        v-if="canEditDowntimes"
        class="change-button"
      >
        {{ $t('general.change') }}
      </div>
    </div>

    <div
      class="summary-card"
      :class="{'summary-card--clickable': canEditDowntimes}"
      @click="canEditDowntimes ? navigate('duration') : null"
    >
      <div>
        <p class="mb-0 font-weight-bold">
          {{ $t('time.duration') }}
        </p>
        <p class="mb-0">
          {{ parsedDowntime.startDate | formatDate }} - {{
            parsedDowntime.endDate | formatDate
          }}{{ parsedDowntime.endDate ? '' : '...' }}
        </p>
        <p class="mb-0">
          {{ parsedDowntime.duration }}
        </p>
      </div>

      <div
        v-if="canEditDowntimes"
        class="change-button"
      >
        {{ $t('general.change') }}
      </div>
    </div>
  </div>
</template>

<script>
import deepClone from '@/utils/deepClone';
import {
  PACKOS_DOWNTIME_CHANGE,
  PACKOS_DOWNTIME_MANAGEMENT,
  PACKOS_MANUAL_DOWNTIME,
} from '@core/dict/permissions';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import './downtimes.scss';

export default {
  name: 'DowntimeVerticalSummary',
  inject: [
    'navigateTo',
    'workEventsTaxonomies',
  ],
  props: {
    lineId: String,
    parsedDowntime: {
      type: Object,
      required: true,
    },
  },
  filters: {
    formatDate(date) {
      if (!date) return '';
      return moment(date * 1000)
        .format('DD MMM HH:mm:ss');
    },
  },
  computed: {
    ...mapGetters('plant', ['plantProperty']),
    ...mapGetters('core', ['canOnElement']),
    ...mapState({
      workEventTaxonomies: state => state.taxonomies.workEventTaxonomies,
    }),
    canEditDowntimes() {
      return (this.canOnElement(PACKOS_DOWNTIME_MANAGEMENT, this.lineId)
        || this.canOnElement(PACKOS_DOWNTIME_CHANGE, this.lineId)
        || this.canOnElement(PACKOS_MANUAL_DOWNTIME, this.lineId));
    },
    workEventDeleted() {
      return this.parsedDowntime.workEvent.softDeleted;
    },
    rootWorkEventDeleted() {
      return this.parsedDowntime?.rootWorkLog?.workEvent?.softDeleted;
    },
    workEventTaxonomy() {
      const code = this.plantProperty('TAXONOMY_CODE_FOR_REGISTERING_DOWNTIMES');
      return this.workEventTaxonomies.find(t => t.code === code);
    },
    showRootCause() {
      return this.parsedDowntime.rootWorkLog;
    },
    isLine() {
      return this.parsedDowntime.isLine;
    },
  },
  methods: {
    navigate(route) {
      this.navigateTo(route);
    },
    getTaxonomyBindingPath(workEventId) {
      const thisProblemBindings = this.workEventsTaxonomies().filter(b => b.workEventId === workEventId);

      return thisProblemBindings
        .map(b => {
          const taxonomy = this.workEventTaxonomies.find(t => t.id === b.taxonomyId);
          const node = (taxonomy?.nodes || []).find(n => n.id === b.taxonomyNodeId);

          let selectedElement = deepClone(node || {});
          let treeNames = '';

          if (!selectedElement && this.innerValue) return '';

          while (selectedElement.parentId) {
            // eslint-disable-next-line no-loop-func
            selectedElement = (taxonomy?.nodes || []).find(e => e.id === selectedElement.parentId);
            treeNames = `${selectedElement.description || selectedElement.code} / ${treeNames}`;
          }

          return `${treeNames} ${node?.description}` || '?';
        })
        .join('/');
    },
  },
};
</script>
