<template>
  <div
    :style="{
      'padding-bottom': displayAxis ? '20px' : null
    }"
    class="position-relative"
  >
    <div class="d-flex align-items-center h-100">
      <div
        :id="`worklog${$uId}`"
        class="worklog-wrapper pb-2"
        @mouseleave="mouseLeave"
        @mousemove.stop="track"
        @mousedown.stop="startZoom"
        @dblclick="doubleClick"
      >
        <canvas
          ref="chart"
          style="width: 100%; height: 100%; border-radius: 10px"
          :height="isMobile && '250' || '50'"
          width="5000"
        />
        <div
          v-show="cursor"
          ref="cursor"
          class="cursor"
        />
        <div
          v-show="cursor && !displayAxis"
          ref="cursorDate"
          class="cursor-date"
        >
          {{ cursor }}
        </div>

        <div
          v-show="zooming"
          ref="zoom-box"
          :style="{
            left: `${zoomLeft}px`,
            width: `${zoomWidth}px`
          }"
          class="zoom-box"
        />
      </div>

      <div
        v-if="showTools"
        class="toolbox"
      >
        <div
          v-show="zoomedIn"
          class="worklog-tool zoom-out"
          @click.stop="zoomOut"
        >
          <i class="fas fa-search-minus" />
        </div>

        <div class="d-flex">
          <div
            v-show="zoomedIn"
            class="worklog-tool w-50 move-left"
            @click.stop="moveLeft"
          >
            <i class="fas fa-chevron-left" />
          </div>
          <div
            v-show="zoomedIn"
            class="worklog-tool w-50 move-right"
            @click.stop="moveRight"
          >
            <i class="fas fa-chevron-right" />
          </div>
        </div>
      </div>
      <div
        v-else
        class="toolbox"
      />

      <div
        v-show="cursor && popoverContent.start"
        :id="`worklog-popover${$uId}`"
        ref="popover"
        class="popover show bs-popover-top worklog-popover"
        :style="{
          'background-color': popoverColor,
        }"
        style="position: absolute; top: -10px;"
      >
        <div class="popover-loss-info">
          <div v-if="popoverContent.isPerformanceLoss">
            <i class="fas fa-tachometer-alt" />
          </div>
          <div v-else-if="popoverContent.isAvailabilityLoss">
            <i class="fas fa-plug" />
          </div>
          <div v-else-if="popoverContent.isUtilizationLoss">
            <i class="fas fa-calendar-minus" />
          </div>
          <div v-else-if="popoverContent.isQualityLoss">
            <i class="fas fa-clipboard-check" />
          </div>
        </div>
        <div
          class="popover-body text-center"
        >
          <StateBadge
            v-if="popoverContent.workState"
            :state="popoverContent.workState"
          />
          <div
            v-if="(!popoverContent.rootWorkLog || popoverContent.rootWorkLog.eventId != popoverContent.eventId)
              && !popoverContent.isDefault"
            class="popover-description"
          >
            <span
              :style="{
                opacity: popoverContent.isDeleted || popoverContent.softDeleted ? 0.5 : 1
              }"
            >
              {{ popoverContent.name }}
            </span>

            <span
              v-if="popoverContent.isDeleted || popoverContent.softDeleted"
              class="badge badge-pill badge-secondary ml-1"
              style="background-color: #F2F2F2; color: #848484; opacity: 0.7;"
            >
              <i class="fas fa-trash-can mr-1" />
              {{ $t('general.deleted') }}
            </span>
          </div>
          <div
            v-if="popoverContent.rootWorkLog && popoverContent.rootWorkLog.workEvent"
            class="popover-rc"
          >
            <span
              :style="{
                opacity: popoverContent.isDeleted || popoverContent.softDeleted ? 0.5 : 1
              }"
            >
              <i class="fas fa-long-arrow-alt-right pr-2" />
              [ <span class="font-weight-bold">
                {{ elementName(popoverContent.rootWorkLog.elementId) }}
              </span> ]

              <StateBadge
                v-if="popoverContent.rootWorkLog.eventId != popoverContent.eventId"
                style="margin-bottom: 2px;"
                :style="{
                  opacity: popoverContent.isDeleted || popoverContent.softDeleted ? 0.5 : 1
                }"
                :state="popoverContent.rootWorkLog.workState"
              />
              {{ popoverContent.rootWorkLog.workEvent.name }}
              <span
                v-if="popoverContent.isDeleted || popoverContent.softDeleted"
                class="badge badge-pill badge-secondary ml-1"
                style="background-color: #F2F2F2; color: #848484; opacity: 0.7;"
              >
                <i class="fas fa-trash-can mr-1" />
                {{ $t('general.deleted') }}
              </span>
            </span>
          </div>
          <div class="popover-dates">
            {{ popoverContent.start }} - {{ popoverContent.end }}<br>
            ({{ popoverContent.duration }})
          </div>
        </div>
      </div>
    </div>
    <div style="margin-right: 50px; height: 10px">
      <TimeAxis
        v-if="displayAxis"
        :timestamp="timestamp + utcOffset"
        :start-date="currentStartDate + utcOffset"
        :end-date="currentEndDate + utcOffset"
        :curr-date-utc="true"
      />
    </div>
  </div>
</template>

<script>
import TimeAxis from '@/components/schedule/TimeAxis';
import CalcDuration from '@/utils/calcDuration';
import moment from 'moment';
import tinycolor from 'tinycolor2';
import { mapGetters } from 'vuex';

export default {
  props: {
    worklogs: {
      type: Array,
      default: () => [],
    },
    zoomable: {
      type: Boolean,
      default: true,
    },
    showCursor: {
      type: Boolean,
      default: true,
    },
    zoom: Object,
    startDate: {
      type: Number,
      required: true,
    },
    endDate: {
      default: Math.floor(Date.now() / 1000),
      type: Number,
    },
    displayAxis: {
      type: Boolean,
      default: true,
    },
    performanceHistory: {
      type: Array,
      default: () => [],
    },
    trackingWorst: {
      type: [Object, undefined],
      default: undefined,
    },
    worklogHoverFn: Function,
    showTools: Boolean,
    focusModified: Boolean,
  },
  data() {
    return {
      alarms: false,
      drawn: false,
      cursor: false,
      zooming: false,
      timestamp: null,
      zoomedIn: false,
      zoomFrom: 0,
      container: {},
      zoomTo: 0,
      currentStartDate: this.startDate,
      currentEndDate: this.endDate || Math.floor(Date.now() / 1000),
      popoverWidth: 1,
      popoverContent: {},
    };
  },
  components: {
    TimeAxis,
  },
  computed: {
    ...mapGetters(['screenWidth', 'isMobile']),
    ...mapGetters('element', ['elementName']),
    utcOffset() {
      return moment().utcOffset() * 60;
    },
    blocks() {
      return this.worklogs
        .filter(el => el.start < this.currentEndDate && el.end > this.currentStartDate)
        .flatMap(x => {
          if (!x.worklog || !x.worklog.workStateName || x.worklog.workStateName.toLowerCase() !== 'work') {
            return [x];
          }

          const performances = this.performanceHistory
            .filter(y => y.end > x.start && y.start < x.end);

          if (performances.length === 0) {
            return [x];
          }

          let curr = x.start;
          const res = [];
          let i = 0;

          while (curr < x.end) {
            const perf = i < performances.length
              ? performances[i]
              : { start: x.end };

            if (perf.start > curr) {
              res.push({
                start: curr,
                end: perf.start,
                averagePerformance: 0,
                expectedPerformance: 1,
              });

              curr = perf.start;
            } else {
              res.push(perf);
              i += 1;
              curr = perf.end;
            }
          }

          return res
            .map(p => ({
              ...x,
              start: Math.max(x.start, p.start),
              end: Math.min(x.end, p.end),
              perf: p,
            }));
        })
        .map(el => ({
          id: el.id,
          wl: {
            color: el.color,
            ...el.worklog,
          },
          color: el.color,
          fill: !el.perf || !el.perf.expectedPerformance
            ? 1
            : Math.min(1, el.perf.averagePerformance / el.perf.expectedPerformance),
          left: this.downtimeOffset(el),
          width: this.downtimeLength(el),
        }));
    },
    popoverColor() {
      if (!this.popoverContent) return '#fff';
      const color = this.popoverContent.color || '#fff';
      return tinycolor(color).lighten(25).setAlpha(0.8).toString();
    },
    period() {
      return this.currentEndDate - this.currentStartDate;
    },
    zoomWidth() {
      const start = this.zoomLeft;
      const end = Math.max(this.zoomFrom, this.zoomTo);
      return Math.max(end - start, 0);
    },
    zoomLeft() {
      return Math.min(this.zoomFrom, this.zoomTo);
    },
  },
  watch: {
    startDate(sd) {
      this.currentStartDate = sd;
    },
    endDate(ed) {
      if (Math.abs(ed - this.currentEndDate) > 10 && !this.zoom && !this.zoom?.end) {
        this.currentEndDate = ed;
      }
    },
    screenWidth() {
      if (this.$refs.chart) {
        this.container = this.$refs.chart.getBoundingClientRect();
      }
    },
    zoom(event) {
      if (!event) this.zoomOut();
      else {
        this.zoomToDates(event.start, event.end);
      }
    },
    blocks() {
      this.update();
    },
    focusModified() {
      this.update();
    },
  },
  methods: {
    tinycolor,
    update() {
      const canvas = this.$refs.chart;
      if (!canvas || !canvas.getContext) return;
      const ctx = canvas.getContext('2d');
      const { width, height } = canvas;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      this.blocks.forEach(b => {
        const trackingEnabled = !!this.trackingWorst;
        const isTracked = trackingEnabled ? (this.trackingWorst.elementId === b.wl.elementId
            && this.trackingWorst.workEventId === b.wl.eventId)
          || (this.trackingWorst.rootElementId === b.wl.elementId
            && this.trackingWorst.rootWorkEventId === b.wl.eventId) : false;

        const isModified = b.wl.modified;
        const hidden = (this.focusModified && !isModified)
          || (trackingEnabled && !isTracked);

        const x = (b.left * width) / 100;
        const w = (b.width * width) / 100;
        const highlighted = hidden
          ? height
          : height * b.fill;

        if (highlighted < height) {
          ctx.fillStyle = tinycolor(b.color).lighten(30).toString();
          ctx.fillRect(x, 0, w, height);
        }

        if (hidden) {
          ctx.fillStyle = tinycolor(b.color).setAlpha(0.2).toString();
        } else {
          ctx.fillStyle = b.color;
        }

        ctx.fillRect(x, (height - highlighted), w, highlighted);
      });
    },
    genPopoverContent() {
      const { timestamp } = this;
      const worklog = this.getWorklogByTimestamp(timestamp);
      if (!worklog) {
        this.popoverContent = {};
        return;
      }
      if (this.worklogHoverFn) {
        this.worklogHoverFn({ timestamp, worklog });
      }

      this.popoverContent = {
        ...worklog,
        timestamp,
        softDeleted: worklog.workEvent?.softDeleted,
        isDefault: worklog.workEvent?.isDefault || false,
        time: this.formatDate(timestamp),
        isDeleted: !worklog.workEvent,
        isPerformanceLoss: worklog.isPerformanceLoss || worklog.isMinorStop,
        isAvailabilityLoss: worklog.isAvailabilityLoss,
        isUtilizationLoss: worklog.isUtilizationLoss,
        name: worklog.workEvent?.name || '',
        start: this.formatDate(worklog.startDate),
        end: this.formatDate(worklog.endDate),
        duration: CalcDuration(worklog.startDate, worklog.endDate),
      };
    },
    formatDate(timestamp) {
      return moment(timestamp * 1000).format('HH:mm:ss');
    },
    downtimeLength({ start, end }) {
      const duration = end ? (end - start) : (this.endDate - start);
      return Math.max(((duration / this.period) * 100), 0);
    },
    downtimeOffset({ start }) {
      return ((start - this.currentStartDate) / this.period) * 100;
    },
    getWorklogByTimestamp(timestamp) {
      if (!this.blocks || Number.isNaN(timestamp)) return null;
      let s = 0;
      let e = this.blocks.length;

      while (s < e) {
        const m = Math.floor((s + e) / 2);
        const el = this.blocks[m].wl;

        if (!el.startDate) return null;
        if (el.startDate <= timestamp
          && (!el.endDate || el.endDate >= timestamp)) {
          return el;
        }
        if (el.startDate >= timestamp) {
          e = m;
        } else if (el.endDate <= timestamp) {
          s = m + 1;
        }
      }

      return null;
    },
    doubleClick(e) {
      const left = this.getOffset(e);
      const timestamp = this.getTimestamp(left);
      const worklog = this.getWorklogByTimestamp(timestamp);
      if (worklog) {
        this.$emit('doubleClicked', worklog.startDateTime);
      }
    },
    moveLeft() {
      if (!this.zoom) return;

      const width = this.zoom.end - this.zoom.start;
      const jump = width * 0.2; // 20% of the current view

      const maxLeft = Math.max(this.startDate, this.zoom.start - jump);
      const right = maxLeft + width;

      this.$emit('zoomed', {
        start: maxLeft,
        end: right,
      });
    },
    moveRight() {
      if (!this.zoom) return;

      const width = this.zoom.end - this.zoom.start;
      const jump = width * 0.2; // 20% of the current view

      const minRight = Math.min(this.endDate, this.zoom.end + jump);
      const left = minRight - width;

      this.$emit('zoomed', {
        start: left,
        end: minRight,
      });
    },
    getOffset(e) {
      if (!this.$refs.chart) return 0;
      const { width, left } = this.container;
      return Math.min(Math.max(0, e.pageX - left), width);
    },
    mouseLeave() {
      this.timestamp = null;
      this.cursor = false;
    },
    zoomOut() {
      if (!this.zoom) {
        this.zoomToDates(this.startDate, this.endDate);
        return;
      }

      const width = this.zoom.end - this.zoom.start;
      const jump = width * 1.5; // show 50% more view

      const left = Math.max(this.startDate, this.zoom.start - (jump / 2));
      const right = Math.min(this.endDate, this.zoom.end + (jump / 2));

      this.zoomToDates(left, right);
      if (left === this.startDate && right === this.endDate) {
        this.$emit('zoomed', null);
      } else {
        this.$emit('zoomed', { start: left, end: right });
      }
    },
    getWidth() {
      return this.container.width;
    },
    startZoom(e) {
      if (!this.zoomable) return;
      this.zooming = true;
      this.zoomFrom = this.getOffset(e);
      this.zoomTo = this.getOffset(e);
    },
    endZoom() {
      this.zooming = false;
      this.cursor = false;
      const to = Math.max(this.zoomTo, this.zoomFrom);
      const from = Math.min(this.zoomFrom, this.zoomTo);

      if (to - from > 1) {
        const start = this.getTimestamp(from);
        const end = this.getTimestamp(to);
        if (start === end) return;

        this.zoomToDates(start, end);
        this.$emit('zoomed', { start: this.currentStartDate, end: this.currentEndDate });
      }
      this.zoomFrom = 0;
      this.zoomTo = 0;
    },
    zoomToDates(start, end) {
      this.zoomedIn = (start - this.startDate !== 0) || (this.endDate - end !== 0);
      this.currentStartDate = start;
      this.currentEndDate = end;
    },
    getTimestamp(offset) {
      const density = this.period / this.getWidth();
      return Math.floor(this.currentStartDate + (offset * density));
    },
    getMsTimestamp(offset) {
      const density = this.period / this.getWidth();
      return Math.floor(this.currentStartDate * 1000 + (offset * density * 1000));
    },
    track(e) {
      // currently not on this worklog
      if (!this.zooming && !(e.target && e.target.closest && e.target.closest(`#worklog${this.$uId}`))) {
        return;
      }
      e.stopPropagation();
      const left = this.getOffset(e);
      this.cursor = true;
      this.$refs.cursor.style.left = `${left}px`;
      this.$refs.cursorDate.style.left = `${left - 20}px`;

      const msTimestamp = this.getMsTimestamp(left);
      this.timestamp = Math.floor(msTimestamp / 1000);
      this.$refs.cursorDate.innerHTML = moment(msTimestamp).format('HH:mm:ss.S');

      this.genPopoverContent();
      // set popover position
      this.$refs.popover.style.left = `${left}px`;

      if (this.zooming) {
        this.zoomTo = this.getOffset(e);
      }
    },
  },
  created() {
    this.currentStartDate = this.startDate;
    this.currentEndDate = this.endDate || Math.floor(Date.now() / 1000);
    this.mouseUpFunction = e => {
      this.endZoom(e);
    };
    this.mouseMoveFunction = e => {
      this.track(e);
    };
    document.addEventListener('mousemove', this.mouseMoveFunction, true);
    document.addEventListener('mouseup', this.mouseUpFunction, true);

    if (this.zoom) {
      this.zoomToDates(this.zoom.start, this.zoom.end);
    }
  },
  mounted() {
    this.container = this.$refs.chart.getBoundingClientRect();
    if (this.blocks) {
      this.update();
    }
  },
  destroyed() {
    if (this.mouseMoveFunction) {
      document.removeEventListener('mousemove', this.mouseMoveFunction, true);
    }
    if (this.mouseUpFunction) {
      document.removeEventListener('mouseup', this.mouseUpFunction, true);
    }
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/vars.icss';

  .diagram-title {
    position: absolute;
    width: 100%;
    top: -20px;
    line-height: 42px;
  }

  .popover-body {
    padding: 3px 10px;
    font-size: 14px;
  }

  .popover-dates {
    font-size: 12px;
  }

  .worklog-popover {
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translate(-50%, -100%);
    min-width: 120px;
    width: 300px;
    z-index: 102;
  }

  .popover-rc {
    font-size: 12px;
  }

  .toolbox {
    min-width: 50px;
  }

  .zoom-out {
    transition: transform 50ms;
    z-index: 1000;
  }

  .worklog-tool {
    background-color: #eee;
    border-radius: 8px;
    margin: 2px;
    font-size: 14px;
    text-align: center;
    line-height: 1;
    padding: 2px;
    cursor: pointer;

    &:hover {
      background-color: #ccc;
    }
  }

  .popover-loss-info {
    font-size: 10px;
    padding-top: 2px;
    position: absolute;
    top: 0px;
    right: 5px;
  }

  .zoom-box {
    position: absolute;
    background-color: rgba(#333, 0.4);
    top: 0;
    left: 0;
    height: 100%;
  }

  .worklog-wrapper {
    position: relative;
    height: 100%;
    padding: 5px 0;
    user-select: none;
  }

  .downtimes-progress {
    height: 100%;
    margin-bottom: 10px;
    position: relative;
  }

  .progress-bar {
    position: absolute;
    left: 0;
    height: 100%;
    transition: width 200ms, left 200ms;
  }

  .popover-description {
    line-height: 1.2;
  }

  .cursor {
    position: absolute;
    top: 2px;
    left: 0;
    width: 1px;
    height: calc(100% - 5px);
    box-shadow: 0px 0px 5px rgba(black, 0.8);
    background-color: $elegant;
  }

  .cursor-date {
    width: 40px;
    position: absolute;
    left: 0;
    top: -8px;
    font-size: 9px;
    font-weight: bold;
    z-index: 1000;
  }

</style>
