<template>
  <div class="text-widget">
    <div
      :style="{
        width: `${percentageHeight}px`,
        height: `${percentageHeight}px`,
      }"
    >
      <OEEChart
        :value="percentageValue"
        :size="percentageSize"
        :style="{
          height: `${percentageHeight}px`,
          width: `${percentageHeight}px`,
        }"
      />
    </div>

    <div
      class="title text-ellipsis w-100 px-1 text-center"
      :class="{
        'mt-2': doubleHeight,
      }"
    >
      {{ rawDetails.title || '-' }}
    </div>
  </div>
</template>

<script>
import OEEChart from '@/components/charts/oees/OEE';
import { customLayoutWidgetFieldType } from '@/components/customLayout/customLayoutDictionary';

export default {
  name: 'CustomLayoutPercentageWidget',
  props: {
    value: Object,
    minValue: Object,
    maxValue: Object,
    rawDetails: Object,

    currentTags: Array,
  },
  components: {
    OEEChart,
  },
  computed: {
    percentageMin() {
      if (!this.minValue) {
        return 0;
      }

      return this.minValue.type === customLayoutWidgetFieldType.tag
        ? this.getTagValueForTag(this.minValue.value)
        : this.minValue.value;
    },
    percentageMax() {
      if (!this.maxValue) {
        return 0;
      }

      return this.maxValue.type === customLayoutWidgetFieldType.tag
        ? this.getTagValueForTag(this.maxValue.value)
        : this.maxValue.value;
    },
    percentageValue() {
      if (!this.value) {
        return 0;
      }

      const top = Number(this.percentageMax) || 0;
      const bottom = Number(this.percentageMin) || 0;
      const value = this.value.type === customLayoutWidgetFieldType.tag
        ? this.getTagValueForTag(this.value.value)
        : this.value.value;
      const v = Number(value) || 0;
      return ((v - bottom) / (top - bottom)) * 100;
    },
    doubleHeight() {
      return this.rawDetails.size.h === 2;
    },
    percentageHeight() {
      if (this.doubleHeight) {
        return 95;
      }
      return 60;
    },
    percentageSize() {
      return this.doubleHeight ? 'md' : 'xs';
    },
  },
  methods: {
    getTagValueForTag(tag) {
      return this.currentTags.find(t => t.nodeId === tag)?.value || '-';
    },
  },
};
</script>

<style scoped lang="scss">
.text-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .title {
    margin-top: -4px;
    font-size: 12px;
  }
}
</style>
