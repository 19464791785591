import { PACKOS_ORDER_EDIT, PACKOS_ORDER_MANAGEMENT } from '@core/dict/permissions';
import moment from 'moment';
import { mapGetters } from 'vuex';
import DateTimePicker from '@ilabo/vue-ctk-date-time-picker';
import { activityStatus, activityType, elementCapabilities } from '@/utils/dictionary';
import ValidationErrors from '@/components/activity/ValidationErrors';

export default function activityForm() {
  return {
    props: {
      activityId: String,
      fixedLineId: String,
      isEditBlocked: Boolean,
      importResult: {
        type: Object,
        required: false,
      },
      showEditCorrelated: Boolean,
    },
    data: () => ({
      activityToSave: {
        lineId: null,
        plannedExecution: {
        },
        expectedEffort: {},
      },
      validate: false,
      pending: false,
      error: null,
    }),
    filters: {
      ifValidated(v, isValidated) {
        return isValidated ? !!v : null;
      },
    },
    components: {
      DateTimePicker, ValidationErrors,
    },
    computed: {
      ...mapGetters('core', ['locale', 'canOnElement']),
      ...mapGetters('plant', [
        'lines',
        'linesWithCapabilities',
      ]),
      ...mapGetters(['plantId']),
      isDisabled() {
        return this.activityToSave.id && (this.isEditBlocked
         || (this.activityToSave.status !== activityStatus.released
         && this.activityToSave.status !== activityStatus.draft));
      },
      lineIdDisabled() {
        return this.isDisabled || this.activityToSave.status === activityStatus.released;
      },
      linesWithOrderViewAccess() {
        return (this.linesWithCapabilities([
          elementCapabilities.ACTIVITIES,
        ]) || []).filter(x =>
          this.canOnElement(PACKOS_ORDER_MANAGEMENT, x.id)
            || this.canOnElement(PACKOS_ORDER_EDIT, x.id))
          .concat({
            id: null,
            name: this.$t('activities.noLine'),
          });
      },
      datesNotValid() {
        if (this.begin && this.end) {
          return moment(this.begin)
            .isSameOrAfter(this.end);
        }
        return this.validate && !this.begin;
      },
      endDateNotValid() {
        return this.type === activityType.cleaning && this.validate && !this.end;
      },
    },
    methods: {
      setFormData(data) {
        this.activityToSave = {
          ...this.activityToSave,
          ...data,
          plannedExecution: {
            begin: data.scheduledExecution?.begin ? moment(data.scheduledExecution.begin).format() : null,
            end: data.scheduledExecution?.end ? moment(data.scheduledExecution.end).format() : null,
          },
          expectedEffort: data.expectedEffort || {},
        };
      },
      showCorrelated() {
        this.$emit('showCorrelated', this.activityToSave.correlationId);
        this.pending = true;
      },
    },
    created() {
      if (this.fixedLineId) {
        this.$set(this.activityToSave, 'lineId', this.fixedLineId);
      }
    },
  };
}
