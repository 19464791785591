<template>
  <div
    :style="{ height: `${height}px`, width: `${width}px`}"
    class="position-relative"
  >
    <Chartist
      :data="chartData"
      :options="chartOptions"
      ratio="ct-square"
      class="gauge-metrics"
      type="Pie"
    />
    <svg
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      style="position: absolute; top: 0; left: 0;"
    >
      <line
        :x2="coordinates.x"
        :y2="coordinates.y"
        x1="50"
        y1="50"
        stroke="black"
        style="stroke-width: 4"
      />
      <circle
        cx="50"
        cy="50"
        r="5"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    baseline: Number,
    warningFrom: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 50,
    },
    width: {
      type: Number,
      default: 50,
    },
  },
  computed: {
    chartData() {
      if (!this.baseline || !this.max) {
        return {
          series: [90, 0, 10],
        };
      }
      const base = (this.baseline / this.max) * 100;
      const warningFrom = this.warningFrom ? ((this.warningFrom - this.baseline) / this.max) * 100 : 0;
      return {
        series: [base, warningFrom, 100 - base - warningFrom],
      };
    },
    chartOptions() {
      return {
        donut: true,
        donutWidth: 10,
        donutSolid: true,
        startAngle: 270,
        total: 200,
        showLabel: false,
      };
    },
    coordinates() {
      const max = Math.max(this.max, 0.01);
      const deg = (this.value / max) * Math.PI;
      const v = (Math.PI / 2) - deg;
      const r = 40;
      const w = 50;
      const x = w - (Math.sin(v) * r);
      const y = w - (Math.cos(v) * r);
      return { x, y };
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .gauge-metrics :deep(.ct-series) {
    &.ct-series-a .ct-slice-donut-solid {
      fill: $green;
    }
    &.ct-series-b .ct-slice-donut-solid {
      fill: $orange;
    }
    &.ct-series-c .ct-slice-donut-solid {
      fill: $red;
    }
  }
</style>
