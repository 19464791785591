<template>
  <div class="widgets-library">
    <div class="header-box d-flex align-items-center py-2">
      <div
        v-if="selectedWidgetType"
        class="back-button"
        @click="selectedWidgetType = ''"
      >
        <i class="fas fa-chevron-left" />
      </div>

      <div v-if="selectedWidgetType">
        <h6 class="mb-0">
          {{ $t('customLayout.selectWidgetSize') }}
        </h6>
        <p class="text-black-50 mb-0 small">
          {{ $t('customLayout.dragAndDropSelectedWidgetIntoTheGrid') }}
        </p>
      </div>

      <div v-else>
        <h5 class="mb-0">
          {{ $t('customLayout.selectWidgetType') }}
        </h5>
      </div>
    </div>

    <div
      v-if="!selectedWidgetType"
      class="widget-type-selector d-flex overflow-hidden overflow-x-auto mt-auto"
      :style="{
        gap: '10px',
        minHeight: `${cellSize * 2.5 + 20}px`,
        height: `${cellSize * 2.5 + 20}px`,
      }"
    >
      <div
        v-for="widgetType in widgetTypes"
        :key="widgetType.type"
        class="widget-wrapper type"
        @click="selectedWidgetType = widgetType.type"
      >
        <div
          class="droppable-element"
          :style="{
            minWidth: `${cellSize * 2}px`,
            width: `${cellSize * 2}px`,
            minHeight: `${cellSize}px`,
            height: `${cellSize}px`,
          }"
        >
          <Component
            :is="customLayoutService.getPlaceholderComponentForWidgetType(widgetType.type)"
            v-if="customLayoutService.getPlaceholderComponentForWidgetType(widgetType.type)"
            v-bind="customLayoutService.getPlaceholderComponentPropsForWidgetComponent({
              w: 2,
              h: 1,
            })"
          />
        </div>

        <div class="widget-type">
          <div class="label">
            {{ widgetType.label }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="widget-selector d-flex overflow-hidden overflow-x-auto mt-auto"
      :style="{
        gap: '10px',
        minHeight: `${cellSize * 2.5 + 20}px`,
        height: `${cellSize * 2.5 + 20}px`,
      }"
    >
      <div
        v-for="(widget, index) in draggableWidgetsInSelectedType"
        :key="`widget-${index}`"
        class="widget-wrapper"
        :style="{
          minWidth: `${cellSize * (Math.max(2, widget.w) + 0.5)}px`,
          width: `${cellSize * (Math.max(2, widget.w) + 0.5)}px`,
        }"
      >
        <div
          class="droppable-element"
          draggable="true"
          unselectable="on"
          :style="{
            minWidth: `${cellSize * widget.w}px`,
            width: `${cellSize * widget.w}px`,
            minHeight: `${cellSize * widget.h}px`,
            height: `${cellSize * widget.h}px`,
          }"
          @drag="drag($event, widget)"
          @dragend="dragend($event, widget)"
        >
          <Component
            :is="customLayoutService.getPlaceholderComponentForWidgetType(selectedWidgetType)"
            v-if="customLayoutService.getPlaceholderComponentForWidgetType(selectedWidgetType)"
            v-bind="customLayoutService.getPlaceholderComponentPropsForWidgetComponent(widget)"
          />
        </div>

        <div class="size-badge">
          {{ getSizeOptionValue(widget) }}
        </div>
      </div>

      <div
        v-if="draggableWidgetsInSelectedType.length === 0"
        class="text-center w-100 pt-5 text-black-50"
      >
        <h4 class="fas fa-info-circle mb-2" />
        <p class="mb-0 small">
          {{ $t('customLayout.noWidgetsAvailable') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { customLayoutWidgetType } from '@/components/customLayout/customLayoutDictionary';
import customLayoutService from '@/components/customLayout/utils/customLayoutService';
import deepClone from '@/utils/deepClone';

const mouseXY = { x: null, y: null };
const DragPos = {
  x: null, y: null, w: 1, h: 1, i: null,
};

export default {
  name: 'CustomLayoutGridEditor',
  props: {
    cellSize: Number,
    rawLayout: Array,
    rawLayoutDetails: Array,
  },
  data: () => ({
    selectedWidgetType: '',

    draggableWidgets: [
      ...customLayoutService.getAllAvailableDraggableWidgets(),
    ],
  }),
  computed: {
    widgetTypes() {
      return [
        {
          type: customLayoutWidgetType.text,
          label: this.$t('customLayout.text'),
        },
        {
          type: customLayoutWidgetType.gaugeChart,
          label: this.$t('customLayout.gaugeChart'),
        },
        {
          type: customLayoutWidgetType.percentageChart,
          label: this.$t('customLayout.percentageChart'),
        },
        {
          type: customLayoutWidgetType.progressBar,
          label: this.$t('customLayout.progressBar'),
        },
      ];
    },
    customLayoutService() {
      return customLayoutService;
    },
    layout: {
      get() {
        return this.rawLayout;
      },
      set(value) {
        this.$emit('update:rawLayout', value);
      },
    },
    layoutDetails: {
      get() {
        return this.rawLayoutDetails;
      },
      set(value) {
        this.$emit('update:rawLayoutDetails', value);
      },
    },
    draggableWidgetsInSelectedType() {
      return this.draggableWidgets.filter(w => w.details.type === this.selectedWidgetType);
    },
  },
  methods: {
    drag(e, widget) {
      let layout = deepClone(this.layout);
      const parentRect = document.getElementById('content').getBoundingClientRect();
      let mouseInGrid = false;
      if (((mouseXY.x > parentRect.left)
          && (mouseXY.x < parentRect.right))
        && ((mouseXY.y > parentRect.top)
          && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true && (layout.findIndex(item => item.i === 'drop')) === -1) {
        layout.push({
          x: (layout.length * 2) % (this.colNum || 12),
          y: layout.length + (this.colNum || 12), // puts it at the bottom
          w: widget.w,
          h: widget.h,
          i: 'drop',
        });
        this.layout = [...layout];
      }
      const index = layout.findIndex(item => item.i === 'drop');
      if (index !== -1) {
        try {
          this.$parent.$refs.gridlayout.$children[layout.length].$refs.item.style.display = 'none';
          // eslint-disable-next-line no-empty
        } catch {}
        const el = this.$parent.$refs.gridlayout?.$children[index];
        el.dragging = { top: mouseXY.y - parentRect.top, left: mouseXY.x - parentRect.left };
        const newPos = el.calcXY(
          mouseXY.y - parentRect.top - this.cellSize / 2,
          mouseXY.x - parentRect.left - this.cellSize / 2,
        );

        if (mouseInGrid === true) {
          if (newPos.x > 12) {
            newPos.x = 12;
          }
          if (newPos.y > 3) {
            newPos.x = 3;
          }
          this.$parent.$refs.gridlayout?.dragEvent('dragstart', 'drop', newPos.x, newPos.y, widget.h, widget.w);
          DragPos.i = String(index);
          DragPos.x = layout[index].x;
          DragPos.y = layout[index].y;
        }
        if (mouseInGrid === false) {
          this.$parent.$refs.gridlayout?.dragEvent('dragend', 'drop', newPos.x, newPos.y, widget.h, widget.w);

          layout = layout.filter(obj => obj.i !== 'drop');
          this.layout = [...layout];
        }
      }
    },
    dragend(e, widget) {
      let layout = deepClone(this.layout);
      const layoutDetails = deepClone(this.layoutDetails);

      const parentRect = document.getElementById('content').getBoundingClientRect();
      let mouseInGrid = false;
      if (((mouseXY.x > parentRect.left)
          && (mouseXY.x < parentRect.right))
        && ((mouseXY.y > parentRect.top)
          && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true) {
        this.$parent.$refs.gridlayout?.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, widget.h, widget.w);
        layout = layout.filter(obj => obj.i !== 'drop');

        if (DragPos.x < 12 && DragPos.y < 3) {
          layout.push({
            x: DragPos.x,
            y: DragPos.y,
            w: widget.w,
            h: widget.h,
            i: new Date().getTime(),
          });
        }

        layoutDetails.push(deepClone(widget.details));

        this.layout = [...layout];
        this.layoutDetails = [...layoutDetails];

        this.$parent.$refs.gridLayout?.dragEvent('dragend', DragPos.i, DragPos.x, DragPos.y, widget.h, widget.w);

        try {
          this.$parent.$refs.gridLayout.$children[this.layout.length].$refs.item.style.display = 'block';
          // eslint-disable-next-line no-empty
        } catch {}
      }
    },
    getSizeOptionValue(option) {
      return `${option.w}x${option.h}`;
    },
  },
  mounted() {
    document.addEventListener('dragover', e => {
      mouseXY.x = e.clientX;
      mouseXY.y = e.clientY;
    }, false);
  },
};
</script>

<style scoped lang="scss">
  .widget-wrapper {
    background-color: #F0F0F0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    width: 200px;
    min-height: 200px;
    height: 180px;
    position: relative;
    border-radius: 8px;
    transition: 0.3s all;
    border: 1px solid transparent;

    &.type {
      cursor: pointer;

      .droppable-element {
        margin-bottom: 32px;
      }

      &:hover {
        border: 1px solid #d3d4d5;
        box-shadow: rgba(0, 0, 0, 0.2) 0 0 6px;
      }
    }
  }

  .widget-type {
    border-radius: 8px;
    background: #fff !important;
    border: 1px solid #F0F1F3;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .label {
      position: absolute;
      background-color: white;
      border-radius: 0 0 8px 8px;
      padding-left: 0.5rem;
      width: calc(100% - 0.5rem);
      bottom: 0.25rem;
      left: 0.25rem;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 13px;
      font-weight: 500;
    }
  }

  .droppable-element {
    border-radius: 8px;
    background: #fff !important;
    border: 1px solid #F0F1F3;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 4px;
    cursor: pointer;
  }

  .header-box {
    border-top: 1px solid #ECECEC;
    border-bottom: 1px solid #ECECEC;
    margin-bottom: 1rem;
    height: 5rem;
    padding: 0 1rem;
  }

  .back-button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #979797;
    color: white;
    margin-right: 1rem;
    cursor: pointer;
    border-radius: 50px;
    font-size: 12px;
    line-height: 0;
  }

  .size-badge {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    width: 28px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 500;
    background-color: #9D9D9D;
    padding: 0.25rem;
    color: white;
    border-radius: 50px;
  }
</style>
