<template>
  <span
    ref="badge"
    class="badge badge-success text-white text-ellipsis overflow-hidden"
    :class="{'text-wrap-normal': textWrap}"
    :style="{maxWidth: maxWidth ? `${maxWidth + 10}px` : 'unset', ...styles}"
  >
    {{ stateInfo && stateInfo.description || '...' }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import colors from '@/utils/colors';

export default {
  props: {
    state: Object,
    eventId: String,
    textWrap: Boolean,
    small: Boolean,
    maxWidth: Number,
  },
  data: () => ({
    isTextEclipsed: false,
  }),
  computed: {
    ...mapGetters('work', ['workStateOfEvent']),
    stateInfo() {
      if (this.state) return this.state;
      if (this.eventId) {
        return this.workStateOfEvent(this.eventId);
      }
      return null;
    },
    styles() {
      const styles = {
        'background-color': colors(this.stateInfo ? (this.stateInfo.colorCode || 'red') : 'grey'),
      };
      if (this.small) {
        styles['font-size'] = '10px';
      }
      return styles;
    },
  },
  methods: {
    colors,
  },
  updated() {
    if (this.$refs.badge) {
      const e = this.$refs.badge;
      const newIsTextEclipsed = e.offsetWidth < e.scrollWidth;
      if (this.isTextEclipsed !== newIsTextEclipsed) {
        this.isTextEclipsed = newIsTextEclipsed;
      }
    }
  },
};
</script>

<style scoped>
  .badge {
    text-transform: uppercase;
    vertical-align: middle;
  }

  .text-ellipsis {
    text-overflow: ellipsis;
  }
</style>
