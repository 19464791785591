<template>
  <div>
    <LModal
      :show.sync="showDetails"
      size="xl"
    >
      <div>
        <OrderList
          v-if="!settle"
          class="mt-5"
          :list="[edited]"
          :selected-id="edited && edited.id"
          @settle="settle = true"
          @edit="formModal = true"
          @activated="handleOrderAction"
          @stopped="handleOrderAction"
          @started="handleOrderAction"
          @deleted="handleOrderAction"
          @audit="auditModal = true; auditActivityId = $event"
          @isEditBlocked="isEditBlocked = $event"
        />
        <OrderSettlementForm
          v-else
          :order="edited"
          @close="settle = false"
          @completed="handleOrderAction"
        />
      </div>
    </LModal>

    <BModal
      v-model="formModal"
      hide-header
      hide-footer
      modal-class="modal-fullscreen"
    >
      <ActivityFormModal
        :activity="edited"
        :is-edited="true"
        @close="formModal = false"
        @saved="$emit('saved', $event); formModal = false"
      />
    </BModal>

    <LModal
      :show.sync="currentProductionStatsModal"
      size="xl"
    >
      <CurrentProductionStats
        v-if="edited"
        :line-id="edited.lineId"
        @close="closeRouteModal"
      />
    </LModal>

    <LModal
      :show.sync="auditModal"
      size="xl"
    >
      <AuditLog
        :activity-id="auditActivityId"
      />
    </LModal>
  </div>
</template>

<script>
import { activityStatus } from '@/utils/dictionary';
import OrderSettlementForm from '@/components/order/OrderSettlementForm';
import OrderList from '@/components/order/OrderList';
// import ActivityResources from '@/components/activity/ActivityResources';
import routeModal from '@/mixins/routeModal';
import ActivityFormModal from '@/components/activity/form/ActivityFormModal';
import CurrentProductionStats from '@/components/modals/CurrentProductionStats';
import AuditLog from '@/components/audit/AuditLog.vue';

export default {
  props: {
    edited: Object,
  },
  data: () => ({
    settle: false,
    showDetails: false,
    formModal: false,
    isEditBlocked: false,
    currentProductionStatsModal: false,
    auditModal: false,
    auditActivityId: '',
    activityStatus,
  }),
  components: {
    AuditLog,
    OrderSettlementForm,
    OrderList,
    ActivityFormModal,
    CurrentProductionStats,
    // ActivityResources,
  },
  mixins: [
    routeModal('reportResources'),
  ],
  computed: {
    displayRouteModal() {
      return this.$route.query.orderId && this.selectedId === this.$route.query.orderId;
    },
  },
  watch: {
    auditModal(v) {
      if (!v) {
        this.auditActivityId = '';
      }
    },
    edited(val) {
      if (val) {
        this.showDetails = true;
      }
    },
    showDetails(val) {
      if (!val) this.$emit('close');
    },
  },
  methods: {
    handleOrderAction() {
      this.formModal = false;
      this.showDetails = false;
      this.settle = false;
      this.$emit('request');
    },
    confirmCurrentProduction() {
      this.reportResources = false;
      this.currentProductionStatsModal = true;
    },
  },
};
</script>

<style scoped>
.order-edit-form {
  width: 100%;
  max-width: 500px;
  margin: 10px auto 0;
}
</style>
